import { AppState } from '../root.reducer';

export const getPaymentIntentSecret = (state: AppState) => state.orderCustomerActions.paymentIntentSecret;
export const getClaimPaymentIntentSecret = (state: AppState) => state.orderCustomerActions.claimPaymentIntentSecret;
export const getIsPollingForPaymentCompletion = (state: AppState) => state.orderCustomerActions.isPolling;

export const getOrderCustomerActionById = (id: string) => (state: AppState) => state.entities.orderCustomerAction[id];

export const getOrderCustomerActionsByIds = (ids: string[]) => (state: AppState) => {
  return ids.map((id) => getOrderCustomerActionById(id)(state));
};

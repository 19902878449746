import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import PaymentContainer from '../Payment';
import { PaymentType } from '../../redux/order-customer-actions/order-customer-actions.types';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import {
  getClaimPaymentIntentSecret,
  getIsPollingForPaymentCompletion,
} from '../../redux/order-customer-actions/order-customer-actions.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  PAY_STORE_KEY,
  getClaimPaymentIntentRequested,
  payRequested,
  GET_CLAIM_PAYMENT_INTENT_STORE_KEY,
} from '../../redux/order-customer-actions/order-customer-actions.actions';
import { getCurrentOrderCustomer } from '../../redux/order/order.selectors';
import { fetchOrderCustomerRequested, FETCH_ORDER_CUSTOMER_STORE_KEY } from '../../redux/order/order.actions';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import { REPOSIT_PAYMENT_WARNING } from '../../constants/reposit';

interface ClaimProposalPaymentProps {
  orderId: string;
}

const WarningMessage = () => {
  return (
    <>
      <div>
        You must provide full payment for this proposal to be considered. If this payment is not provided, these end of tenancy
        charges will be considered accepted and you will take full liability of the charges.
      </div>
      <div style={{ marginTop: '1em' }}>{REPOSIT_PAYMENT_WARNING}</div>
    </>
  );
};

const ClaimProposalPayment: React.FC<ClaimProposalPaymentProps> = ({ orderId }) => {
  const dispatch = useDispatch();
  const currentCustomerId = useSelector(getCurrentCustomerId);
  // NEEDS TO CHANGE
  const paymentIntentSecret = useSelector(getClaimPaymentIntentSecret);
  const getPayRepositLoadingSelector = createLoadingSelector([PAY_STORE_KEY]);
  const isPaymentLoading = useSelector(getPayRepositLoadingSelector);
  const currentOrderCustomer = useSelector(getCurrentOrderCustomer);
  const amount = get(currentOrderCustomer, 'fee');
  const pageLoadingSelector = createLoadingSelector([FETCH_ORDER_CUSTOMER_STORE_KEY, GET_CLAIM_PAYMENT_INTENT_STORE_KEY]);
  const isPageLoading = useSelector(pageLoadingSelector);
  const isPolling = useSelector(getIsPollingForPaymentCompletion);

  useEffect(() => {
    if (orderId && currentCustomerId) {
      dispatch(
        getClaimPaymentIntentRequested(
          currentCustomerId,
          orderId,
          OrderCustomerActionDTOTypeIdEnum.CLAIMPROPOSALPAYREMAININGBALANCE
        )
      );
      dispatch(fetchOrderCustomerRequested({ customerId: currentCustomerId, orderId }));
    }
  }, [dispatch, currentCustomerId, orderId]);

  const submitPayment = (stripe: Stripe, cardNumberElement: StripeCardNumberElement) =>
    dispatch(payRequested({ stripe, paymentIntentSecret, type: PaymentType.CLAIM_PROPOSAL, cardNumberElement }));

  const isLoading = isPageLoading || isPolling || isPaymentLoading;

  const render = () => {
    return (
      <PaymentContainer
        fullWidth
        isSubmitting={isLoading}
        amount={`${amount}`}
        submitCard={submitPayment}
        type={PaymentType.CLAIM_PROPOSAL}
        warningMessage={WarningMessage}
        title="Pay your proposal"
      />
    );
  };

  return render();
};

export default ClaimProposalPayment;

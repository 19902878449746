import React from "react";
import styled from "styled-components";
import { isString } from "lodash";

import { Label } from "../Typography";
import Button from "../Button";
import { Option } from "../../interfaces/option.interface";
import { getBreakpoint } from "../../base/style";

const FilterWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0;

  @media screen and (max-width: ${getBreakpoint("lg")}) {
    margin: 0 0 12px;
  }
`;

const FilterLabel = styled(Label)`
  margin: 0 20px 0 0;
  width: 75px;
  flex: 0 0 75px;

  @media screen and (min-width: ${getBreakpoint("lg")}) {
    flex: 0 0 auto;
    width: auto;
  }
`;

const FilterOptions = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
`;

const FilterButton = styled(Button)`
  font-family: ${props => props.theme.typography.face.secondary};
  flex: 0 1 auto;
  margin: 1px 10px 6px 0;
  text-transform: capitalize !important;
  font-size: 14px;
  padding: 6px 20px;

  @media screen and (min-width: ${getBreakpoint("lg")}) {
    margin: 1px 10px 0 0;
  }
`;

type OnSelect = (option: string) => void;

interface FilterProps {
  label?: string;
  onSelect: OnSelect;
  selected?: string;
  // I tried string[] | Option[]
  // errored with "has no compatible call signatures"
  // https://stackoverflow.com/questions/50924952/typescript-has-no-compatible-call-signatures
  // the (string | Option)[] is less than ideal though
  options: (string | Option)[];
}

const renderOption = (
  option: string | Option,
  onSelect: OnSelect,
  selected?: string
): JSX.Element => {
  if (isString(option)) {
    return (
      <FilterButton
        buttonType="tertiary"
        key={option}
        onClick={() => onSelect(option)}
        selected={selected === option}
      >
        {option}
      </FilterButton>
    );
  } else {
    return (
      <FilterButton
        buttonType="tertiary"
        key={option.label}
        onClick={() => onSelect(option.value)}
        selected={selected === option.value}
      >
        {option.label}
      </FilterButton>
    );
  }
};

const renderOptions = ({
  options,
  onSelect,
  selected
}: FilterProps): JSX.Element[] => {
  return options.map(option => renderOption(option, onSelect, selected));
};

export default (props: FilterProps): JSX.Element => (
  <FilterWrapper>
    {props.label && <FilterLabel>{props.label}</FilterLabel>}
    <FilterOptions>{renderOptions(props)}</FilterOptions>
  </FilterWrapper>
);

import React, { Fragment, useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { Container, Row, Col } from 'react-grid-system';
import PageTitle from '../../../../components/PageTitle';
import PaymentContainer from '../../../Payment';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentOrderCustomer } from '../../../../redux/order/order.selectors';
import {
  getPaymentIntentRequested,
  GET_PAYMENT_INTENT_STORE_KEY,
  PAY_STORE_KEY,
  payRequested,
  pollPaymentSuccessCancelled,
} from '../../../../redux/order-customer-actions/order-customer-actions.actions';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import {
  getPaymentIntentSecret,
  getIsPollingForPaymentCompletion,
} from '../../../../redux/order-customer-actions/order-customer-actions.selectors';
import { PaymentType } from '../../../../redux/order-customer-actions/order-customer-actions.types';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { REPOSIT_PAYMENT_WARNING } from '../../../../constants/reposit';

interface PaymentProps {}

const WarningMessage = () => {
  return <div>{REPOSIT_PAYMENT_WARNING}</div>;
};

const Payment: React.FC<PaymentProps> = (props) => {
  const dispatch = useDispatch();
  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const { order, customer, fee } = useSelector(getCurrentOrderCustomer);
  const paymentIntentSecret = useSelector(getPaymentIntentSecret);
  const getPaymentIntentLoadingSelector = createLoadingSelector([GET_PAYMENT_INTENT_STORE_KEY]);
  const isGetPaymentIntentLoading = useSelector(getPaymentIntentLoadingSelector);
  const getPayRepositLoadingSelector = createLoadingSelector([PAY_STORE_KEY]);
  const isPayRepositLoading = useSelector(getPayRepositLoadingSelector);
  const submitPayment = (stripe: Stripe, cardNumberElement: StripeCardNumberElement) =>
    dispatch(payRequested({ stripe, paymentIntentSecret, type: PaymentType.REPOSIT, cardNumberElement }));
  const isPolling = useSelector(getIsPollingForPaymentCompletion);

  useEffect(() => {
    dispatch(getPaymentIntentRequested(customer.id, order.id));
  }, [dispatch, order.id, customer.id, paymentIntentSecret]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  });

  if (isPolling && !timeoutId) {
    const id = setTimeout(() => {
      dispatch(pollPaymentSuccessCancelled());
      dispatch(
        push(`/checkout/${order.id}/${customer.id}/finish`, {
          waitingPayment: true,
        })
      );
    }, 30000);
    setTimeoutId(id);
  }

  const isLoading = isGetPaymentIntentLoading || isPolling || isPayRepositLoading;

  return (
    <Fragment>
      <Container>
        <Row>
          <Col lg={10} push={{ lg: 1 }}>
            <PageTitle>Payment</PageTitle>
          </Col>
        </Row>
      </Container>
      <PaymentContainer
        amount={`${fee}`}
        warningMessage={WarningMessage}
        isSubmitting={isLoading}
        submitCard={submitPayment}
        type={PaymentType.REPOSIT}
        title="Your share of this Reposit is"
      />
    </Fragment>
  );
};

export default Payment;

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Button from '../../../../components/Button';
import PageTitle from '../../../../components/PageTitle';
import RepositCard from '../../../../components/RepositCard';
import styled from 'styled-components';
import FieldWithLabel from '../../../../components/FormFields/FieldWithLabel';
import DatePicker from '../../../../components/FormFields/DatePicker';
import { getBreakpoint } from '../../../../base/style';
import { Input } from '../../../../components/FormFields';
import { P1 } from '../../../../components/Typography';
import GenderSelect, { GenderFormOptions } from '../../../../components/GenderSelect';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from '../../../../redux/selectors/user.selectors';
import * as Yup from 'yup';
import { get } from 'lodash';
import { Formik, FormikProps } from 'formik';
import { UPDATE_USER_STORE_KEY } from '../../../../redux/user/user.actions';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants/dates';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import { FullPageLoader } from '../../../../components/Loading/index';
import {
  updateAboutYouRequested,
  UPDATE_ABOUT_YOU_STORE_KEY,
} from '../../../../redux/order-customer-actions/order-customer-actions.actions';
import { getCurrentOrderCustomer } from '../../../../redux/order/order.selectors';

interface AboutYouProps {}

const Action = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${getBreakpoint('md')}) {
    justify-content: flex-end;
    flex-direction: row;
  }

  p {
    margin: 0 20px 0 0;
  }
`;

const Field = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 96%;
  }
`;

const FieldAlt = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    float: right;
    max-width: 96%;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin-top: 0px;
  }
`;

const Schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('This must be a valid email address').required('Email address is required'),
  attributes: Yup.object().shape({
    dob: Yup.string().nullable().required('Date of birth is required'),
    gender: Yup.string().required('Gender is required'),
    mobileNumber: Yup.string()
      .min(11, 'Incorrectly formatted phone number')
      .max(15, 'Incorrectly formatted phone number')
      .matches(/^\+?[0-9]+$/, 'Incorrectly formatted phone number')
      .required('Required'),
  }),
});

interface UserAttributes {
  dob: Date | undefined;
  gender?: GenderFormOptions;
  mobileNumber?: string;
}

interface AboutYouFormValues {
  firstName: string;
  lastName: string;
  email: string;
  attributes: UserAttributes;
}

const AboutYou: React.FC<AboutYouProps> = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { order, customer } = useSelector(getCurrentOrderCustomer);
  const attributes = get(currentUser, 'attributes', {});
  const dob = get(attributes, 'dob');
  const isLoadingSelector = createLoadingSelector([UPDATE_USER_STORE_KEY]);
  const isLoading = useSelector(isLoadingSelector);
  const isUpdateSubmittingSelector = createLoadingSelector([UPDATE_ABOUT_YOU_STORE_KEY]);
  const isUpdateSubmitting = useSelector(isUpdateSubmittingSelector);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Formik
      initialValues={{
        firstName: get(currentUser, 'firstName', ''),
        lastName: get(currentUser, 'lastName', ''),
        email: get(currentUser, 'email', ''),
        attributes: {
          dob: dob ? moment(dob).toDate() : undefined,
          gender: get(currentUser, 'attributes.gender', undefined),
          mobileNumber: get(currentUser, 'attributes.mobileNumber', undefined),
        },
      }}
      validationSchema={Schema}
      onSubmit={(data) => {
        dispatch(
          updateAboutYouRequested(customer.id, order.id, {
            firstName: data.firstName,
            lastName: data.lastName,
            dob: moment(data.attributes.dob).format('YYYY-MM-DD'),
            gender: data.attributes.gender,
            mobileNumber: data.attributes.mobileNumber,
          })
        );
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }: FormikProps<AboutYouFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <PageTitle tooltip="Before you can purchase your Reposit we need to collect a bit of information about you. This information is used to conduct checks to determine wether you are eligible to use Reposit.">
                    Tell us a bit about yourself
                  </PageTitle>
                </Col>
              </Row>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <RepositCard title="What's your name?">
                    <Container fluid>
                      <Row>
                        <Col lg={6} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel label="First name" touched={touched.firstName} error={errors.firstName}>
                              <Input
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="firstName"
                                touched={touched.firstName}
                                error={errors.firstName}
                              />
                            </FieldWithLabel>
                          </Field>
                        </Col>
                        <Col lg={6} style={{ padding: 0 }}>
                          <FieldAlt>
                            <FieldWithLabel label="Last name" touched={touched.lastName} error={errors.lastName}>
                              <Input
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="lastName"
                                touched={touched.lastName}
                                error={errors.lastName}
                              />
                            </FieldWithLabel>
                          </FieldAlt>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel
                              label="Don’t worry, we’ve already got your email"
                              touched={touched.email}
                              error={errors.email}
                            >
                              <Input
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="email"
                                touched={touched.email}
                                error={errors.email}
                                disabled
                              />
                            </FieldWithLabel>
                          </Field>
                        </Col>
                        <Col lg={6} style={{ padding: 0 }}>
                          <FieldAlt>
                            <FieldWithLabel
                              label="Mobile number"
                              touched={touched.attributes && touched.attributes.mobileNumber}
                              error={errors.attributes && errors.attributes.mobileNumber}
                            >
                              <Input
                                value={values.attributes.mobileNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="attributes.mobileNumber"
                                touched={touched.attributes && touched.attributes.mobileNumber}
                                error={errors.attributes && errors.attributes.mobileNumber}
                                placeholder="e.g. 07000 000 000"
                              />
                            </FieldWithLabel>
                          </FieldAlt>
                        </Col>
                      </Row>
                    </Container>
                  </RepositCard>
                </Col>
              </Row>
              <Row>
                <Col lg={5} push={{ lg: 1 }} style={{ display: 'flex' }}>
                  <RepositCard title="What’s your date of birth?">
                    <FieldWithLabel
                      label="Date of Birth"
                      touched={touched.attributes && touched.attributes.dob}
                      error={errors.attributes && errors.attributes.dob}
                    >
                      <DatePicker
                        showYearDropdown
                        name="attributes.dob"
                        value={values.attributes.dob}
                        dateFormat={DATE_FORMAT}
                        onChange={(date) => {
                          setFieldValue('attributes.dob', date);
                        }}
                        onBlur={handleBlur}
                        placeholder={'DD/MM/YYYY'}
                        touched={touched.attributes && touched.attributes.dob}
                        error={errors.attributes && errors.attributes.dob}
                        maxDate={moment().subtract(18, 'years').toDate()}
                      />
                    </FieldWithLabel>
                  </RepositCard>
                </Col>
                <Col lg={5} push={{ lg: 1 }} style={{ display: 'flex' }}>
                  <GenderSelect
                    selected={values.attributes.gender}
                    touched={touched.attributes && touched.attributes.gender}
                    error={errors.attributes && errors.attributes.gender}
                    onSelect={(gender) => {
                      setFieldValue('attributes.gender', gender);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <Action>
                    <P1>Next: Address History</P1>
                    <ButtonWrapper>
                      <Button buttonType="primary" type="submit" disabled={isUpdateSubmitting}>
                        Continue
                      </Button>
                    </ButtonWrapper>
                  </Action>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default AboutYou;

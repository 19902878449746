import React from 'react';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import { P2, Header5 } from '../Typography';
import { TenantEntity } from '../../redux/entities/entities.types';

interface TenantListProps {
  tenants?: TenantEntity[];
  rounded?: boolean;
}

const Tenants = styled.div<{ rounded?: boolean }>`
  background: ${props => props.theme.colors.bgAccent};
  border-radius: ${props => (props.rounded ? `0 0 8px 8px` : '0')};
  margin: 24px 5px 4px;
  padding: 12px 36px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const List = styled.ul`
  margin: 12px 0 0;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    margin: 0 0 0 24px;
  }
`;

const Tenant = styled.li`
  margin: 0 33px 0 0;

  p {
    margin: 0;
  }
`;

const Label = styled(Header5)`
  align-items: center;
  display: flex;
  margin: 0 42px 0 0;
  padding: 0;
`;

const TenantList: React.FC<TenantListProps> = ({ rounded, tenants }) => {
  return (
    <Tenants rounded={rounded}>
      <Label>Tenants</Label>
      <List>
        {tenants &&
          tenants.map((tenant: TenantEntity) => (
            <Tenant key={tenant.id}>
              <P2>
                {tenant.firstName} {tenant.lastName}
              </P2>
            </Tenant>
          ))}
      </List>
    </Tenants>
  );
};

export default TenantList;

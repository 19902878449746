import {
  PaymentPlanInstalmentActionTypes,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS,
} from '../../payment-plan-instalment/payment-plan-instalment.actions';
import { PaymentPlanEntity } from '../entities.types';
import { PaymentPlanInstalmentDTOStatusEnum } from '@reposit/api-client/dist';

export const paymentPlanEntityReducer = (state: any = {}, action: PaymentPlanInstalmentActionTypes) => {
  switch (action.type) {
    case POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS: {
      const { paymentPlanId, paymentPlanInstalmentId } = action.payload;

      const pp: PaymentPlanEntity = state[paymentPlanId];

      const newInstalments = pp.instalments?.map((i) => {
        if (i.id === paymentPlanInstalmentId) {
          return {
            ...i,
            status: PaymentPlanInstalmentDTOStatusEnum.PAID,
          };
        }
        return i;
      });

      return {
        ...state,
        [paymentPlanId]: {
          ...state[paymentPlanId],
          instalments: newInstalments,
        },
      };
    }
  }
  return state;
};

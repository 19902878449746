import React, { Fragment, useEffect } from 'react';
import { P1 } from '../../../../components/Typography';
import styled from 'styled-components';
import { Row, Container, Col } from 'react-grid-system';
import RepositProgress from '../../../../components/RepositProgress/index';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentOrderCustomer } from '../../../../redux/order/order.selectors';
import ProductSummary from '../../../../components/ProductSummary/index';
import { fetchOrderCustomerRequested, FETCH_ORDER_CUSTOMER_STORE_KEY } from '../../../../redux/order/order.actions';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import { FullPageLoader } from '../../../../components/Loading/index';
import SuccessPayment from './SuccessPayment';
import WaitingPayment from './WaitingPayment';
import { penceToPounds } from '../../../../utils/currency';

interface FinishProps {
  location: any;
  match: any;
}

export const ProductDescription = styled(P1)`
  margin: 0;
  padding: 0;

  span {
    font-weight: 700;
  }
`;

const FlashWrapper = styled.div`
  margin: 24px 0 0;
`;

const RouterContainer = styled.div`
  padding: 70px 0;
`;

const Finish: React.FC<FinishProps> = ({ location, match }) => {
  const dispatch = useDispatch();
  const { customerId, orderId } = match.params;
  const waitingPayment = location.state && location.state.waitingPayment;
  const orderCustomer = useSelector(getCurrentOrderCustomer);
  const orderCustomerLoadingSelector = createLoadingSelector([FETCH_ORDER_CUSTOMER_STORE_KEY]);
  const isOrderCustomerLoading = useSelector(orderCustomerLoadingSelector);

  useEffect(() => {
    if (customerId && orderId) {
      dispatch(fetchOrderCustomerRequested({ customerId, orderId }));
    }
  }, [dispatch, customerId, orderId]);

  if (!orderCustomer || isOrderCustomerLoading) {
    return <FullPageLoader />;
  }

  return (
    <Fragment>
      <Container>
        <Row>
          <Col sm={12}>
            <ProductSummary
              name="Your Reposit"
              description={
                <ProductDescription>
                  Reposits are equal to 1 week’s rent. This means the total price is{' '}
                  <span>£{penceToPounds(orderCustomer.fee)}</span>
                </ProductDescription>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>{<RepositProgress currentUrl={location.pathname} orderActions={orderCustomer.actions || []} />}</Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={12}>
            <FlashWrapper />
          </Col>
        </Row>
      </Container>
      <RouterContainer>{!waitingPayment ? <SuccessPayment /> : <WaitingPayment />}</RouterContainer>
    </Fragment>
  );
};

export default Finish;

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import React from 'react';

import CheckoutForm from '../../components/CheckoutForm';
import { appConfig } from '../../appConfig';

const stripePromise = loadStripe(appConfig.stripe.apiKey);

interface ElementsFormProps {
  warningMessage?: React.FC;
  isSubmitting: boolean;
  submitCard: (stripe: Stripe, cardNumberElement: StripeCardNumberElement) => void;
  buttonText?: string;
  redWarningMessage?: boolean;
  showCheckbox?: boolean;
}

export const ElementsForm: React.FC<ElementsFormProps> = ({
  warningMessage,
  isSubmitting,
  submitCard,
  buttonText,
  redWarningMessage = true,
  showCheckbox = true,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        warningMessage={warningMessage}
        isSubmitting={isSubmitting}
        submitCard={submitCard}
        buttonText={buttonText}
        redWarningMessage={redWarningMessage}
        showCheckbox={showCheckbox}
      />
    </Elements>
  );
};

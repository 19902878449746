import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';

export interface PaymentPlanInstalmentState {
  instalmentPaymentIntentSecret: string;
  isPolling: boolean;
}

export interface FetchInstalmentPaymentIntentPayload {
  paymentPlanId: string;
  paymentPlanInstalmentId: string;
  paymentType: InstalmentPaymentType;
}

export enum InstalmentPaymentType {
  REMAINING_BALANCE = 'REMAINING_BALANCE',
  INSTALMENT = 'INSTALMENT',
}

export interface InstalmentPayPayload {
  stripe: Stripe;
  cardNumberElement: StripeCardNumberElement;
  paymentIntentSecret: string;
  paymentPlanInstalmentId: string;
  paymentPlanId: string;
}

export interface PollInstalmentPaymentSuccessPayload {
  paymentIntentId: string;
  paymentPlanInstalmentId: string;
  paymentPlanId: string;
}

import { unwrapResult } from '@reduxjs/toolkit';
import {
  ClaimDTOStatusEnum,
  ClaimProposalDTOStatusEnum,
  ClaimResponseDocumentDTO,
  CreateClaimItemProposalDTO,
  PaymentPlanDTOStatusEnum,
} from '@reposit/api-client';
import { Location } from 'history';
import { get } from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { useAppDispatch } from '../..';
import HelpIcon from '../../assets/svg/help.svg';
import Megaphone from '../../assets/svg/megaphone.svg';
import WaitingGraphic from '../../assets/svg/waiitng-illustration.svg';
import { RepositTheme } from '../../base/theme';
import ApproveClaimResponseUpdateModal from '../../components/ApproveClaimResponseUpdateModal/ApproveClaimResponseUpdateModal';
import { RoundButton } from '../../components/Button';
import ClaimAction from '../../components/ClaimAction';
import ClaimAppeal from '../../components/ClaimAppeal/index';
import ClaimDocuments from '../../components/ClaimDocuments';
import ClaimItems from '../../components/ClaimItems';
import FlashMessage from '../../components/FlashMessage/index';
import { FullPageLoader } from '../../components/Loading/index';
import RepositCard from '../../components/Reposit/RepositCard';
import { Caption, Header2, P2 } from '../../components/Typography';
import { getCurrentCustomerId, getCurrentUserId } from '../../redux/account/account.selectors';
import {
  fetchClaimRequested,
  RESPOND_TO_CLAIM_STORE_KEY,
  setIsClaimResponseUpdateModalShowing,
  updateClaimResponseRequested,
  UPDATE_CLAIM_RESPONSE_STORE_KEY,
} from '../../redux/claim/claim.actions';
import { getClaimById, getIsClaimResponseUpdateModalShowing, getOrderedClaimDocuments } from '../../redux/claim/claim.selectors';
import { createClaimItemProposalThunk, updateClaimItemProposalThunk } from '../../redux/claim/claim.thunk';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { PAY_STORE_KEY } from '../../redux/order-customer-actions/order-customer-actions.actions';
import { fetchOrderCustomerRequested } from '../../redux/order/order.actions';
import { AppState } from '../../redux/root.reducer';
import { getAddressByClaimId } from '../../redux/selectors/address.selectors';
import {
  getFirstAgentProposalTotalAmount,
  getFirstTenantProposal,
  getHasTenantAcceptedAnyCharges,
  getHasTenantDisputed,
  getHasTenantSubmitted,
  getIsTenantAuthorisingPayment,
  getIsTenantDeciding,
  getIsTenantNegotiating,
  getLatestAgentProposal,
  getLatestAgentProposalResponseDeadline,
  getLatestProposalTotalAmount,
  getMediationClaimItemsByClaimId,
  getSecondAgentProposal,
  getHasTenantAutoAcceptedAnyCharges,
  getSecondAgentProposalTotalAmount,
  getClaimOrder,
} from '../../redux/selectors/mediation.selectors';
import { getAddressFirstLine, penceToPounds } from '../../utils/common.utils';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../FlashMessage/index';
import { push } from 'connected-react-router';
import { getPaymentPlanByClaimId } from '../../redux/payment-plan/payment-plan.selectors';
import { getCurrentOrderCustomer } from '../../redux/order/order.selectors';

interface ClaimProps {
  location: Location<any>;
  match: any;
}

interface ClaimHeaderProps {
  isLatestAgentProposalFinal: boolean;
  hasTenantAutoAcceptedAnyCharges: boolean;
  address?: string;
  daysRemaining: number;
}

const Title = styled(Header2)`
  color: ${(props) => props.theme.colors.negative};
  margin: 24px 0 1rem;
`;

const ArbitratingTitle = styled(Header2)`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const ArbitratingCaption = styled(Caption)`
  text-align: center;
  margin-bottom: 48px;
`;

const BoldText = styled.span`
  font-weight: bold;
  display: inline;
`;

const FlashWrapper = styled.div`
  margin: 24px 0;
`;

const ClaimFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 12px;
`;

const ClaimTotal = styled(P2)<{ warning: boolean }>`
  text-align: right;
  margin: 0;

  span {
    color: ${(props) => (props.warning ? RepositTheme.colors.negative : RepositTheme.colors.body)};
    font-family: ${(props) => props.theme.typography.face.primary};
    font-weight: bold;
    font-size: 20px;
    margin: 0 16px 0 10px;
  }
`;

export const LiabilityTotal = styled(P2)`
  text-align: right;
  margin: 0;
  color: ${RepositTheme.colors.negative};

  span {
    font-family: ${(props) => props.theme.typography.face.primary};
    font-weight: bold;
    font-size: 20px;
    margin: 0 16px 0 10px;
  }
`;

const ClaimTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 16px 16px 28px;
`;

const respondBeforeDeadlineCaption = (daysRemaining: number) => (
  <Caption>
    You must respond within
    <BoldText>
      {' '}
      {daysRemaining} {daysRemaining > 1 ? 'days' : 'day'}
    </BoldText>
    , otherwise you will automatically become liable for the full amount.
  </Caption>
);

const respondAfterDeadlineCaption = (hasTenantAutoAcceptedAnyCharges: boolean) => {
  if (hasTenantAutoAcceptedAnyCharges) {
    return (
      <Caption>
        The deadline to respond to the charges has passed and you have become automatically liable for the full amount.
      </Caption>
    );
  } else {
    return (
      <Caption>
        You must respond <BoldText>immediately</BoldText>, otherwise you will automatically become liable for the full amount.
      </Caption>
    );
  }
};

const ClaimHeader: React.FC<ClaimHeaderProps> = ({
  hasTenantAutoAcceptedAnyCharges,
  isLatestAgentProposalFinal,
  address,
  daysRemaining,
}) => {
  const hasTimeRemaining = daysRemaining > 0;
  return (
    <Row>
      <Col lg={4} style={{ textAlign: 'center' }}>
        <img src={Megaphone} width="273" alt="megaphone" />
      </Col>
      <Col lg={8}>
        <Title>
          {hasTimeRemaining
            ? `You have ${daysRemaining} ${daysRemaining > 1 ? 'days' : 'day'} to respond`
            : `You must ${hasTenantAutoAcceptedAnyCharges ? 'pay' : 'respond'} immediately`}
        </Title>
        {isLatestAgentProposalFinal ? (
          <Caption>
            Your agent or landlord has reviewed your proposal and responded with their own. You may choose to accept this final
            settlement, or continue with a formal dispute process.
          </Caption>
        ) : (
          <Caption>
            End of tenancy charges have been raised on your Reposit
            {address ? (
              <span>
                {' '}
                for <BoldText>{address}</BoldText>
              </span>
            ) : null}
            . See below for details.
          </Caption>
        )}
        {hasTimeRemaining
          ? respondBeforeDeadlineCaption(daysRemaining)
          : respondAfterDeadlineCaption(hasTenantAutoAcceptedAnyCharges)}
      </Col>
    </Row>
  );
};

const AwaitingArbitrationHeader: React.FC<{ isDispute: boolean }> = ({ isDispute }) => {
  const phrase = isDispute ? 'Claim Manager' : 'Landlord';
  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <ArbitratingTitle>Awaiting response from your {phrase}</ArbitratingTitle>
          <ArbitratingCaption>
            {`Just relax for now and we will let you know when you
            need to do something.`}
          </ArbitratingCaption>
        </Col>
      </Row>
      <Row>
        <Col lg={12} style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <img src={WaitingGraphic} style={{ maxWidth: '643px', width: '100%' }} alt="Waiting People" />
        </Col>
      </Row>
    </Fragment>
  );
};

const HelpButton = styled(RoundButton)`
  margin-right: 10px;
`;

const MaxLiabilityWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type Action = { type: 'setHasGoneBack'; payload: boolean } | { type: 'decrementCount' };
type State = { hasGoneBack: boolean; hasGoneBackCount: number };

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'setHasGoneBack':
      return {
        ...state,
        hasGoneBack: action.payload,
        hasGoneBackCount: action.payload ? state.hasGoneBackCount + 1 : 0,
      };
    case 'decrementCount':
      return {
        ...state,
        hasGoneBackCount: state.hasGoneBackCount - 1,
      };
    default:
      return state;
  }
}

const Claim: React.FC<ClaimProps> = ({ match, location }) => {
  const dispatch = useAppDispatch();

  const [{ hasGoneBack, hasGoneBackCount }, reducerDispatch] = useReducer(reducer, { hasGoneBack: false, hasGoneBackCount: 0 });

  const setHasGoneBack = (payload: boolean, force?: boolean) => {
    if (!payload && hasGoneBackCount === 2 && !force) {
      reducerDispatch({ type: 'decrementCount' });
    } else {
      reducerDispatch({ type: 'setHasGoneBack', payload });
    }
  };

  const { claimId } = match.params;
  const claim = useSelector((state: AppState) => getClaimById(state, claimId));

  const claimOrder = useSelector(getClaimOrder(claimId));
  const currentOrderCustomer = useSelector(getCurrentOrderCustomer);

  const isModalShowing = useSelector(getIsClaimResponseUpdateModalShowing);
  const currentCustomerId = useSelector(getCurrentCustomerId);
  const isTenantAuthorisingPayment = useSelector((state: AppState) => getIsTenantAuthorisingPayment(state, claimId));

  const currentUserId = useSelector(getCurrentUserId);
  const claimRespondents = get(claim, 'respondents', []);
  const claimRespondent = claimRespondents.find((claimRespondent: any) => claimRespondent.userId === currentUserId);
  const claimRespondentId = get(claimRespondent, 'id');
  const claimResponseId = get(claimRespondent, 'claimResponse.id');
  const claimDisputeMessage = get(claim, 'tenantDisputeMessage');
  const claimDetails = get(claim, 'details', '');
  const isMediationEnabled = get(claim, 'mediationEnabled', false);

  const { pathname, search } = location;
  const [urlFlagAdded, setUrlFlagAdded] = useState(false);

  useEffect(() => {
    if (isMediationEnabled && !search && !urlFlagAdded) {
      dispatch(push(`${pathname}?mediationEnabled=true`));
      setUrlFlagAdded(true);
    }
  }, [isMediationEnabled, dispatch, search, pathname, urlFlagAdded, setUrlFlagAdded]);

  const address = useSelector((state: AppState) => getAddressByClaimId(state, claimId));

  const claimResponseDocuments =
    claim && claim.responseDocuments ? claim.responseDocuments.map((rd: ClaimResponseDocumentDTO) => rd.document) : [];
  const [flashMessage, dismissFlashMessage] = useFlashMessage([RESPOND_TO_CLAIM_STORE_KEY, PAY_STORE_KEY]);

  const orderedClaimDocuments = useSelector((state: AppState) => getOrderedClaimDocuments(state, claimId));

  const isLoadingSelector = createLoadingSelector([UPDATE_CLAIM_RESPONSE_STORE_KEY]);
  const isLoading = useSelector(isLoadingSelector);

  const secondAgentProposal = useSelector((state: AppState) => getSecondAgentProposal(state, claimId));

  const isAgentSecondProposalDraft = secondAgentProposal && secondAgentProposal.status === ClaimProposalDTOStatusEnum.DRAFT;

  const latestAgentProposal = useSelector((state: AppState) => getLatestAgentProposal(state, claimId));
  const isLatestAgentProposalFinal = latestAgentProposal && ((latestAgentProposal as any).type as any) === 'FINAL';
  const latestAgentProposalResponseDeadline = useSelector((state: AppState) =>
    getLatestAgentProposalResponseDeadline(state, claimId)
  );
  const daysRemaining = moment(latestAgentProposalResponseDeadline, '').diff(moment().startOf('day'), 'day');

  const tenantProposal = useSelector((state: AppState) => getFirstTenantProposal(state, claimId));
  const tenantProposalId = get(tenantProposal, 'id', '');
  const isTenantNegotiating = useSelector((state: AppState) => getIsTenantNegotiating(state, claimId));
  const hasTenantAutoAcceptedAnyCharges = useSelector((state: AppState) => getHasTenantAutoAcceptedAnyCharges(state, claimId));
  const hasTenantAcceptedAnyCharges = useSelector((state: AppState) => getHasTenantAcceptedAnyCharges(state, claimId));
  const hasTenantDisputed = useSelector((state: AppState) => getHasTenantDisputed(state, claimId));
  const hasTenantSubmitted = useSelector((state: AppState) => getHasTenantSubmitted(state, claimId));
  const isTenantDeciding = useSelector((state: AppState) => getIsTenantDeciding(state, claimId));
  const claimItems = useSelector((state: AppState) => getMediationClaimItemsByClaimId(state, claimId));

  const latestTotalProposalAmount = useSelector((state: AppState) => getLatestProposalTotalAmount(state, claimId));
  const firstAgentProposalAmount = useSelector((state: AppState) => getFirstAgentProposalTotalAmount(state, claimId));
  const secondAgentProposalAmount = useSelector((state: AppState) => getSecondAgentProposalTotalAmount(state, claimId));

  const totalProposalAmount = hasGoneBack ? secondAgentProposalAmount || firstAgentProposalAmount : latestTotalProposalAmount;

  const isInMediation = !!(claim && claim.status === ClaimDTOStatusEnum.MEDIATION);
  const isArbitrated = !!(claim && claim.status === ClaimDTOStatusEnum.ARBITRATED);
  const isAwaitingArbitration = !!(claim && claim.status === ClaimDTOStatusEnum.AWAITINGARBITRATION);
  const isAwaitingSubstantiation = !!(claim && claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION);
  const isAwaitingRespondentsOrPayment = !!(
    claim &&
    (claim.status === ClaimDTOStatusEnum.AWAITINGRESPONDENTS || claim.status === ClaimDTOStatusEnum.AWAITINGARBITRATIONADMINFEE)
  );
  const isAwaitingArbAdminFee = !!(claim && claim.status === ClaimDTOStatusEnum.AWAITINGARBITRATIONADMINFEE);
  const claimItemsViewOnly = !isTenantNegotiating || hasGoneBack;
  const claimLimit: number = get(claim, 'limit', 0);
  const claimLimitExceeded = totalProposalAmount && totalProposalAmount > claimLimit;
  const showClaimAppeal = (isAwaitingArbitration || isAwaitingSubstantiation) && claimDisputeMessage;

  const paymentPlan = useSelector(getPaymentPlanByClaimId(claimId));
  const hasPaymentPlan =
    paymentPlan &&
    currentOrderCustomer &&
    paymentPlan.orderCustomerId === currentOrderCustomer.id &&
    paymentPlan.status !== PaymentPlanDTOStatusEnum.DEFAULT;

  const createCounterProposal = async (payload: CreateClaimItemProposalDTO) => {
    return dispatch(createClaimItemProposalThunk({ claimId, claimProposalId: tenantProposalId, payload })).then(unwrapResult);
  };

  const updateCounterProposal = async (payload: CreateClaimItemProposalDTO, claimItemProposalId: string) => {
    return dispatch(
      updateClaimItemProposalThunk({ claimId, claimProposalId: tenantProposalId, claimItemProposalId, payload })
    ).then(unwrapResult);
  };

  const tooltipText = `With Reposit, the landlord is covered for up to 8 weeks worth of rent. This means that you are only liable for up to £${penceToPounds(
    claimLimit
  )}, even if the total claim exceeds this amount.`;

  useEffect(() => {
    dispatch(fetchClaimRequested(claimId));
  }, [dispatch, claimId]);

  useEffect(() => {
    if (currentCustomerId && claimOrder && !currentOrderCustomer) {
      dispatch(fetchOrderCustomerRequested({ customerId: currentCustomerId, orderId: claimOrder.id }));
    }
  }, [dispatch, claimOrder, currentCustomerId, currentOrderCustomer]);

  if (!claim || !claim.items || !claim.documents) return <FullPageLoader />;

  if (hasPaymentPlan && paymentPlan) {
    return <Redirect to={`/payment-plan/${paymentPlan.id}`} />;
  }

  if (isArbitrated) {
    return <Redirect to={`/arbitrations/${claim.arbitration.id}`} />;
  }

  if (!isAwaitingArbitration && !isAwaitingRespondentsOrPayment && !isArbitrated && !isInMediation && !isAwaitingSubstantiation) {
    return <Redirect to="/" />;
  }

  const showChargesHaveBeenRaised =
    isAwaitingSubstantiation || isTenantDeciding || isAwaitingArbAdminFee || isAwaitingArbitration || hasTenantAcceptedAnyCharges;

  const isAtRootOfClaimDisputeForSecondProposal =
    (claimDisputeMessage && hasGoneBackCount === 2) || (!claimDisputeMessage && hasGoneBack);

  return (
    <Fragment>
      {isModalShowing && (
        <ApproveClaimResponseUpdateModal
          onAccept={() =>
            // investigate this
            dispatch(updateClaimResponseRequested({ message: '', claimId, claimRespondentId, claimResponseId }))
          }
          onDismiss={() => dispatch(setIsClaimResponseUpdateModalShowing(false))}
          isLoading={isLoading}
        />
      )}
      <Container>
        <Row>
          <Col sm={12}>
            <FlashWrapper>
              {flashMessage ? (
                <FlashMessage onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMOUT} payload={flashMessage} />
              ) : undefined}
            </FlashWrapper>
          </Col>
        </Row>
      </Container>
      <Container style={{ paddingTop: 36 }}>
        {isAwaitingArbitration ||
        hasTenantSubmitted ||
        isAgentSecondProposalDraft ||
        (isAwaitingSubstantiation && hasTenantDisputed) ? (
          <AwaitingArbitrationHeader isDispute={hasTenantDisputed} />
        ) : (
          <ClaimHeader
            hasTenantAutoAcceptedAnyCharges={hasTenantAutoAcceptedAnyCharges}
            isLatestAgentProposalFinal={!!isLatestAgentProposalFinal}
            address={address && getAddressFirstLine(address)}
            daysRemaining={daysRemaining}
          />
        )}
        {(isAwaitingRespondentsOrPayment ||
          (isAwaitingSubstantiation && !hasTenantDisputed) ||
          (claim && !claim.mediationEnabled && !isAwaitingArbitration) ||
          (isInMediation && !hasTenantSubmitted && !isAgentSecondProposalDraft)) && (
          <ClaimAction
            claim={claim}
            claimResponseDocuments={claimResponseDocuments}
            createCounterProposal={createCounterProposal}
            updateCounterProposal={updateCounterProposal}
            claimItems={claimItems}
            setHasGoneBack={(val, force) => setHasGoneBack(val, force)}
            hasGoneBack={hasGoneBack}
            hasGoneBackCount={hasGoneBackCount}
          />
        )}
        {(!isTenantNegotiating || hasGoneBack) &&
          // check if they're authorising payment
          !isTenantAuthorisingPayment &&
          // check if they have disputed - but allow them if they are at the root of the choices having gone back
          (!isAwaitingArbAdminFee || isAtRootOfClaimDisputeForSecondProposal) &&
          // accept go back only has one layer
          (!hasTenantAcceptedAnyCharges || (hasGoneBack && hasTenantAcceptedAnyCharges)) && (
            <Row>
              <Col lg={12}>
                <RepositCard flush title={showChargesHaveBeenRaised ? 'Charges Raised' : 'Your Proposal'}>
                  <ClaimItems
                    viewOnly={claimItemsViewOnly}
                    createCounterProposal={createCounterProposal}
                    updateCounterProposal={updateCounterProposal}
                    isTenantNegotiating={isTenantNegotiating}
                    claimItems={claimItems}
                    hasGoneBack={hasGoneBack}
                  />
                  <ClaimFooter>
                    <ClaimTotalWrapper>
                      <ClaimTotal warning={false}>
                        Total: <span>£{penceToPounds(totalProposalAmount || 0)}</span>
                      </ClaimTotal>
                      {claimLimitExceeded ? (
                        <MaxLiabilityWrapper>
                          <HelpButton icon={HelpIcon} data-tip={tooltipText} />
                          <LiabilityTotal>
                            Your maximum liability: <span>£{penceToPounds(claimLimit)}</span>
                          </LiabilityTotal>
                        </MaxLiabilityWrapper>
                      ) : null}
                    </ClaimTotalWrapper>
                  </ClaimFooter>
                </RepositCard>
              </Col>
            </Row>
          )}
        {showClaimAppeal ? <ClaimAppeal claimId={claim.id} /> : null}
        {claimDetails &&
        // same logic here as above about going back
        ((hasGoneBack && hasTenantAcceptedAnyCharges) ||
          isAtRootOfClaimDisputeForSecondProposal ||
          (!isTenantAuthorisingPayment && !isAwaitingArbAdminFee && !hasTenantAcceptedAnyCharges)) ? (
          <Row>
            <Col>
              <RepositCard title="Supporting Information">
                <P2>{claimDetails}</P2>
              </RepositCard>
            </Col>
          </Row>
        ) : null}
        {orderedClaimDocuments &&
        orderedClaimDocuments.length &&
        // same logic here as above about going back
        ((hasGoneBack && hasTenantAcceptedAnyCharges) ||
          isAtRootOfClaimDisputeForSecondProposal ||
          (!isTenantAuthorisingPayment && !isAwaitingArbAdminFee && !hasTenantAcceptedAnyCharges)) ? (
          <Row>
            <Col lg={12}>
              <ClaimDocuments documents={orderedClaimDocuments} />
            </Col>
          </Row>
        ) : null}
      </Container>
    </Fragment>
  );
};

export default Claim;

import { OrderCustomerActionDTOTypeIdEnum, OrderDTOProductIdEnum } from '@reposit/api-client/dist';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../..';
import Button from '../../components/Button';
import FlashMessage, { FlashState } from '../../components/FlashMessage';
import { Header1, Header3 } from '../../components/Typography';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import { setFlashMessage } from '../../redux/flash-messages/flash-messages.actions';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  getClaimPaymentIntentRequested,
  payRequested,
  PAY_STORE_KEY,
  pollPaymentSuccessCancelled,
  POLL_PAYMENT_SUCCESS_STORE_KEY,
} from '../../redux/order-customer-actions/order-customer-actions.actions';
import {
  getClaimPaymentIntentSecret,
  getIsPollingForPaymentCompletion,
} from '../../redux/order-customer-actions/order-customer-actions.selectors';
import { PaymentType } from '../../redux/order-customer-actions/order-customer-actions.types';
import {
  fetchPaymentIntentSecretRequested,
  FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_STORE_KEY,
  instalmentPayRequested,
  INSTALMENT_PAY_STORE_KEY,
  pollInstalmentPaymentSuccessCancelled,
  POLL_INSTALMENT_PAYMENT_SUCCESS_STORE_KEY,
} from '../../redux/payment-plan-instalment/payment-plan-instalment.actions';
import {
  getInstalmentIsPolling,
  getInstalmentPaymentIntentSecret,
} from '../../redux/payment-plan-instalment/payment-plan-instalment.selectors';
import { InstalmentPaymentType } from '../../redux/payment-plan-instalment/payment-plan-instalment.types';
import { getFailedPaymentPlanInstalment, getPaymentPlanById } from '../../redux/payment-plan/payment-plan.selectors';
import { AppState } from '../../redux/root.reducer';
import { WarningMessage } from '../AddPaymentMethod';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../FlashMessage';
import PaymentContainer from '../Payment';
import { PaymentOptionButton, PaymentOptionWrapper } from './components/PaymentOptions';

const ButtonContainer = styled.div`
  display: flex;
`;

interface ManualPaymentProps {
  match: any;
}

const getActionType = (productIsClaim: boolean, isRemainingBalanceAction: boolean) => {
  if (productIsClaim) {
    if (isRemainingBalanceAction) {
      return OrderCustomerActionDTOTypeIdEnum.CLAIMPAYREMAININGBALANCE;
    } else {
      return OrderCustomerActionDTOTypeIdEnum.CLAIMPAY;
    }
  } else {
    if (isRemainingBalanceAction) {
      return OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE;
    } else {
      return OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY;
    }
  }
};

const ManualPayment: React.FC<ManualPaymentProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { paymentPlanId } = match.params;
  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const currentCustomerId = useSelector(getCurrentCustomerId);
  const instalmentPaymentIntentSecret = useSelector(getInstalmentPaymentIntentSecret);
  const remainingBalancePaymentIntentSecret = useSelector(getClaimPaymentIntentSecret);
  const isPollingAction = useSelector(getIsPollingForPaymentCompletion);
  const isPollingInstalment = useSelector(getInstalmentIsPolling);
  const getPayLoadingSelector = createLoadingSelector([PAY_STORE_KEY, INSTALMENT_PAY_STORE_KEY]);
  const isPaying = useSelector(getPayLoadingSelector);
  const isPolling = isPollingAction || isPollingInstalment;

  const paymentPlan = useSelector((state: AppState) => getPaymentPlanById(state, paymentPlanId));
  const { id: orderId, productId } = paymentPlan.orderCustomer.order;
  const { actions: orderCustomerActions, fee } = paymentPlan.orderCustomer;
  const productIsClaim = productId === OrderDTOProductIdEnum.CLAIM;

  const remainingBalanceAction =
    orderCustomerActions &&
    orderCustomerActions.find(
      (oca) =>
        oca.typeId === OrderCustomerActionDTOTypeIdEnum.CLAIMPAYREMAININGBALANCE ||
        oca.typeId === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE
    );

  const remainingBalanceAmountRemaining = get(remainingBalanceAction, 'details.amountRemaining');
  const fallbackActionAmountRemaining = fee;
  const actionAmountRemaining = remainingBalanceAmountRemaining || fallbackActionAmountRemaining;

  const failedInstalment = useSelector((state: AppState) => getFailedPaymentPlanInstalment(state, paymentPlanId));
  const [paymentType, setPaymentType] = useState<InstalmentPaymentType>(
    failedInstalment ? InstalmentPaymentType.INSTALMENT : InstalmentPaymentType.REMAINING_BALANCE
  );
  const paymentTypeIsInstalment = paymentType === InstalmentPaymentType.INSTALMENT;
  const paymentText = paymentTypeIsInstalment
    ? 'The amount due for this instalment is'
    : 'The remaining balance of your Payment Plan is';
  const amountToPay: number =
    failedInstalment && paymentType === InstalmentPaymentType.INSTALMENT ? failedInstalment?.amount : actionAmountRemaining;

  const shouldShowPaymentOptions = !!failedInstalment;

  const isSubmitting = isPolling || isPaying;

  useEffect(() => {
    if (paymentType === InstalmentPaymentType.INSTALMENT && failedInstalment) {
      dispatch(fetchPaymentIntentSecretRequested(paymentPlan.id, failedInstalment.id));
    } else if (paymentType === InstalmentPaymentType.REMAINING_BALANCE) {
      dispatch(
        getClaimPaymentIntentRequested(currentCustomerId, orderId, getActionType(productIsClaim, !!remainingBalanceAction))
      );
    }
  }, [
    dispatch,
    orderId,
    currentCustomerId,
    paymentType,
    failedInstalment,
    paymentPlan.id,
    productIsClaim,
    remainingBalanceAction,
  ]);

  const submitPayment = (stripe: Stripe, cardNumberElement: StripeCardNumberElement) => {
    return paymentTypeIsInstalment && failedInstalment
      ? dispatch(
          instalmentPayRequested({
            stripe,
            paymentIntentSecret: instalmentPaymentIntentSecret,
            cardNumberElement,
            paymentPlanId: paymentPlan.id,
            paymentPlanInstalmentId: failedInstalment.id,
          })
        )
      : dispatch(
          payRequested({
            stripe,
            paymentIntentSecret: remainingBalancePaymentIntentSecret,
            type: productIsClaim ? PaymentType.CLAIM : PaymentType.ARBITRATION,
            cardNumberElement,
          })
        );
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  });

  if (isPolling && !timeoutId) {
    const id = setTimeout(() => {
      if (paymentTypeIsInstalment) {
        dispatch(pollInstalmentPaymentSuccessCancelled());
      } else {
        dispatch(pollPaymentSuccessCancelled());
      }
      dispatch(
        setFlashMessage({
          key: POLL_INSTALMENT_PAYMENT_SUCCESS_STORE_KEY,
          message: 'Your payment is taking longer than expected! Please contact us via live chat if this issue persists.',
          state: FlashState.ERROR,
        })
      );
    }, 30000);
    setTimeoutId(id);
  }

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    INSTALMENT_PAY_STORE_KEY,
    PAY_STORE_KEY,
    FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_STORE_KEY,
    POLL_INSTALMENT_PAYMENT_SUCCESS_STORE_KEY,
    POLL_PAYMENT_SUCCESS_STORE_KEY,
  ]);

  return (
    <Container>
      <Row>
        <Col>
          <Header1>Payment plan</Header1>
        </Col>
      </Row>
      {!failedInstalment && !isSubmitting ? (
        <Row>
          <Col>
            <ButtonContainer>
              <NavLink to={`/payment-plan/${paymentPlan.id}`}>
                <Button reverse buttonType="primary">
                  Back
                </Button>
              </NavLink>
            </ButtonContainer>
          </Col>
        </Row>
      ) : null}

      {flashMessagePayload ? (
        <FlashMessage payload={flashMessagePayload} onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMOUT} />
      ) : null}
      <Row>
        <Col>
          {shouldShowPaymentOptions ? (
            <Container>
              <Row>
                <Col sm={12}>
                  <div style={{ paddingTop: '1.75rem' }}>
                    <PaymentOptionWrapper>
                      <Header3>What would you like to pay?</Header3>
                      <div>
                        <PaymentOptionButton
                          paymentType={InstalmentPaymentType.REMAINING_BALANCE}
                          selected={!paymentTypeIsInstalment}
                          buttonType="tertiary"
                          onClick={() => setPaymentType(InstalmentPaymentType.REMAINING_BALANCE)}
                        >
                          Remaining Balance
                        </PaymentOptionButton>
                        <PaymentOptionButton
                          paymentType={InstalmentPaymentType.INSTALMENT}
                          selected={paymentTypeIsInstalment}
                          buttonType="tertiary"
                          onClick={() => setPaymentType(InstalmentPaymentType.INSTALMENT)}
                        >
                          Instalment
                        </PaymentOptionButton>
                      </div>
                    </PaymentOptionWrapper>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : null}
          <PaymentContainer
            warningMessage={WarningMessage}
            isSubmitting={isSubmitting}
            submitCard={submitPayment}
            title={paymentText}
            amount={`${amountToPay}`}
            type={productIsClaim ? PaymentType.CLAIM : PaymentType.ARBITRATION}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ManualPayment;

import { merge } from 'lodash';
import { EntityState } from './entities.types';
import { EntityActionTypes, UPDATE_ENTITIES } from './entities.actions';
import { combineReducers, AnyAction } from 'redux';
import { userEntityReducer } from './reducers/user.reducer';
import { claimEntityReducer } from './reducers/claim.reducer';
import { claimItemProposalEntityReducer } from './reducers/claimItemProposal.reducer';
import { paymentPlanEntityReducer } from './reducers/payment-plan.reducer';

const initialState: EntityState = {
  arbitration: {},
  address: {},
  document: {},
  customer: {},
  landlord: {},
  order: {},
  property: {},
  tenancy: {},
  tenancyOrder: {},
  user: {},
  tenant: {},
  organization: {},
  invite: {},
  orderCustomer: {},
  orderCustomerAction: {},
  claim: {},
  claimProposal: {},
  claimItemProposal: {},
  checkout: {},
  claimItem: {},
  claimItemDocument: {},
  reference: {},
  check: {},
  claimResponseDocument: {},
  claimRespondent: {},
  tenancyAnniversary: {},
  paymentPlan: {},
  paymentPlanAction: {},
};

export default (state: EntityState = initialState, action: EntityActionTypes) => {
  if (action.type === UPDATE_ENTITIES) {
    // this does not mutate the orginal state - v important
    return merge({}, state, action.payload);
  }

  return combineReducers<EntityState>({
    arbitration: (state: any = {}, action: AnyAction) => state,
    address: (state: any = {}, action: AnyAction) => state,
    customer: (state: any = {}, action: AnyAction) => state,
    document: (state: any = {}, action: AnyAction) => state,
    landlord: (state: any = {}, action: AnyAction) => state,
    order: (state: any = {}, action: AnyAction) => state,
    property: (state: any = {}, action: AnyAction) => state,
    tenancy: (state: any = {}, action: AnyAction) => state,
    tenancyOrder: (state: any = {}, action: AnyAction) => state,
    tenancyAnniversary: (state: any = {}, action: AnyAction) => state,
    tenant: (state: any = {}, action: AnyAction) => state,
    user: userEntityReducer,
    organization: (state: any = {}, action: AnyAction) => state,
    invite: (state: any = {}, action: AnyAction) => state,
    orderCustomer: (state: any = {}, action: AnyAction) => state,
    orderCustomerAction: (state: any = {}, action: AnyAction) => state,
    claim: claimEntityReducer,
    claimItem: (state: any = {}, action: AnyAction) => state,
    claimProposal: (state: any = {}, action: AnyAction) => state,
    claimItemProposal: claimItemProposalEntityReducer,
    claimItemDocument: (state: any = {}, action: AnyAction) => state,
    checkout: (state: any = {}, action: AnyAction) => state,
    reference: (state: any = {}, action: AnyAction) => state,
    check: (state: any = {}, action: AnyAction) => state,
    claimResponseDocument: (state: any = {}, action: AnyAction) => state,
    claimRespondent: (state: any = {}, action: AnyAction) => state,
    paymentPlan: paymentPlanEntityReducer,
    paymentPlanAction: (state: any = {}, action: AnyAction) => state,
  })(state, action);
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaymentPlanActionDTO, PaymentPlanActionsApi, UpdateUserAddressHistoryDTO } from '@reposit/api-client/dist';
import { PaymentMethod, Stripe, StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { getErrorMessage } from '../../utils/common.utils';
import { standardSyncEntitiesAndGetResults } from '../entities/entities.sagas';
import { PaymentPlanActionEntity } from '../entities/entities.types';
import { fetchPaymentPlanThunk } from '../payment-plan/payment-plan.thunk';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { createStandardPaymentPlanActionsApi, runThunkWithAuth } from '../utils/api.utils';

interface FetchPaymentPlanActionPayload {
  id: string;
  paymentPlanId: string;
}

interface UpdateAddressPayload {
  apiPayload: UpdateUserAddressHistoryDTO;
  paymentPlanId: string;
}

interface UpdateCardDetailsPayload {
  paymentPlanId: string;
  stripe: Stripe;
  cardNumberElement: StripeCardNumberElement;
}

interface ConfirmPaymentPlanActionPayload {
  paymentPlanId: string;
}

export const fetchPaymentPlanActionThunk = createAsyncThunk<
  PaymentPlanActionEntity,
  FetchPaymentPlanActionPayload,
  { state: AppState }
>(`payment-plan-action/fetch`, async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api: PaymentPlanActionsApi = createStandardPaymentPlanActionsApi(state);
    const apiResponse: AxiosResponse<PaymentPlanActionDTO> = await runThunkWithAuth(
      () => api.getPaymentPlanAction(payload.id, payload.paymentPlanId),
      dispatch
    );
    const entity: PaymentPlanActionEntity = standardSyncEntitiesAndGetResults(
      apiResponse.data,
      SCHEMA.paymentPlanAction,
      dispatch
    );
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    console.error(error);
    throw e;
  }
});

export const updateAddressPaymentPlanActionThunk = createAsyncThunk<void, UpdateAddressPayload, { state: AppState }>(
  'payment-plan-action/address',
  async (payload, thunkAPI) => {
    const { paymentPlanId, apiPayload } = payload;
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    try {
      const api: PaymentPlanActionsApi = createStandardPaymentPlanActionsApi(state);
      await runThunkWithAuth(() => api.updateAddress(paymentPlanId, apiPayload), dispatch);
      await dispatch(fetchPaymentPlanThunk({ id: paymentPlanId }));
    } catch (e) {
      const error = getErrorMessage(e);
      console.error(error);
      throw e;
    }
  }
);

export const updateCardDetailsPaymentPlanActionThunk = createAsyncThunk<void, UpdateCardDetailsPayload, { state: AppState }>(
  'payment-plan-action/card-details',
  async (payload, thunkAPI) => {
    const { paymentPlanId, stripe, cardNumberElement } = payload;
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    try {
      const {
        paymentMethod,
        error,
      }: {
        paymentMethod?: PaymentMethod | undefined;
        error?: StripeError | undefined;
      } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });

      if (!paymentMethod || error) {
        throw error;
      }
      const api: PaymentPlanActionsApi = createStandardPaymentPlanActionsApi(state);
      await runThunkWithAuth(() => api.updateCardDetails(paymentPlanId, { paymentMethodId: paymentMethod.id }), dispatch);
      await dispatch(fetchPaymentPlanThunk({ id: paymentPlanId }));
      dispatch(push(`/payment-plan/${paymentPlanId}/payment-plan-live`));
    } catch (e) {
      throw e;
    }
  }
);

export const confirmPaymentPlanThunk = createAsyncThunk<void, ConfirmPaymentPlanActionPayload, { state: AppState }>(
  'payment-plan-action/confirm',
  async (payload, thunkAPI) => {
    const { paymentPlanId } = payload;
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    try {
      const api: PaymentPlanActionsApi = createStandardPaymentPlanActionsApi(state);
      await runThunkWithAuth(() => api.confirmPaymentPlan(paymentPlanId), dispatch);
      await dispatch(fetchPaymentPlanThunk({ id: paymentPlanId }));
    } catch (e) {
      const error = getErrorMessage(e);
      console.error(error);
      throw e;
    }
  }
);

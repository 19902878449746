import React from 'react';
import Card from '../../Card';
import { getBreakpoint } from '../../../base/style';
import styled from 'styled-components';
import { P2, Header3, Caption, Header5 } from '../../Typography';
import { getAddressFirstLine } from '../../../utils/common.utils';
import CalendarIcon from '../../../assets/svg/calendar-purple.svg';
import moment from 'moment';
import TenantList from '../../TenantList';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentCustomerId } from '../../../redux/account/account.selectors';
import { OrderWithOrderCustomersDTOStatusIdEnum } from '@reposit/api-client';
import classnames from 'classnames';
import { getOrderCustomerById } from '../../../redux/order/order.selectors';
import { AppState } from '../../../redux/root.reducer';
import { OrderCustomerEntity } from '../../../redux/entities/entities.types';
import { penceToPounds } from '../../../utils/currency';
import Button from '../../Button/index';
import CardMessage from '../../CardMessage/index';

interface RepositSummaryProps {
  reposit: any;
}

const SummaryContainer = styled.div`
  padding: 0 5px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 5px 0 40px;
  }
`;

const ColLeft = styled.div`
  padding: 24px 36px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: 24px 0 0;
  }
`;

const ColRight = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    max-width: 280px;
  }
`;

const NoAddress = styled(P2)`
  font-style: italic;
`;

const Address = styled.div`
  margin-bottom: 1rem;
  width: 100%;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const FirstLineAddress = styled(Header3)`
  line-height: 1.2;
  margin: 0;
  padding: 90;
`;

const TownPostcode = styled(Caption)`
  padding: 2px 0 0;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 12px;
  }
`;

const RepositStatus = styled.p`
  border-radius: 8px;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: #fff;
  padding: 7px 20px 9px;

  &.complete {
    background: ${(props) => props.theme.colors.positive};
  }

  &.pending {
    background: ${(props) => props.theme.colors.warning};
  }

  &.checkedOut {
    background: ${(props) => props.theme.colors.disabled};
  }
`;

const PPM = styled(Header3)`
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;

  span {
    color: ${(props) => props.theme.colors.placeholder};
    font-family: ${(props) => props.theme.typography.face.secondary};
    font-size: 20px;
    font-weight: normal;
    margin: 0 8px;
  }
`;

const TenancyDates = styled.div`
  margin: 24px 0 36px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    margin: 0 0 0 24px;
  }
`;

const Label = styled(Header5)`
  align-items: center;
  display: flex;
  margin: 0 14px 0 0;
  padding: 0;

  &:before {
    background: transparent url(${CalendarIcon}) no-repeat center center;
    background-size: 16px;
    content: '';
    display: block;
    height: 20px;
    margin: -3px 10px 0 0;
    width: 20px;
  }
`;

const Dates = styled(P2)`
  color: ${(props) => props.theme.colors.body2};
  margin: 6px 0 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 1px 0 0;
    padding: 0;
  }
`;

const Meta = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const RepositSummaryWrapper = styled.div<{ isExpired: boolean; isActionable: boolean }>`
  opacity: ${(props) => (props.isExpired ? 0.4 : 1)};
  cursor: ${(props) => (props.isActionable ? 'pointer' : 'none')};
  pointer-events: ${(props) => (props.isActionable ? 'default' : 'none')};
`;

export const useRepositStatus = (reposit: any, customerId: string): [JSX.Element, boolean] => {
  let StatusComponent: JSX.Element;
  let isActionRequired: boolean;
  const { order, tenancy } = reposit;
  const { statusId } = order;
  const repositStatusClass = classnames({
    complete: statusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE,
    pending: statusId !== OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE,
    checkedOut: statusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE && tenancy.checkout,
  });

  const currentOrderCustomer = reposit.order.orderCustomers.find(
    (orderCustomer: OrderCustomerEntity) => orderCustomer.customer.id === customerId
  );

  const orderCustomer = useSelector((state: AppState) => getOrderCustomerById(state, currentOrderCustomer.id));

  if (statusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE) {
    if (tenancy.checkout) {
      StatusComponent = <RepositStatus className={repositStatusClass}>This Reposit is closed</RepositStatus>;
      isActionRequired = false;
    } else {
      StatusComponent = <RepositStatus className={repositStatusClass}>This Reposit is Active</RepositStatus>;
      isActionRequired = false;
    }
  } else {
    if (tenancy.tenants.length > 1) {
      StatusComponent = (
        <RepositStatus className={repositStatusClass}>
          {orderCustomer.nextAction ? 'You have outstanding actions' : 'Awaiting other tenants'}
        </RepositStatus>
      );
    } else {
      StatusComponent = (
        <RepositStatus className={repositStatusClass}>
          {orderCustomer.nextAction ? 'You have outstanding actions' : 'Processing...'}
        </RepositStatus>
      );
    }
    isActionRequired = !!orderCustomer.nextAction;
  }

  return [StatusComponent, isActionRequired];
};

const RepositSummary: React.FC<RepositSummaryProps> = ({ reposit }) => {
  const { order, tenancy } = reposit;
  const address = tenancy.property.address;
  const customerId = useSelector(getCurrentCustomerId);
  const [StatusComponent, isActionRequired] = useRepositStatus(reposit, customerId);

  return (
    <RepositSummaryWrapper
      isExpired={!!reposit.tenancy.completedAt}
      isActionable={isActionRequired && !reposit.tenancy.completedAt}
    >
      <NavLink to={`checkout/${order.id}/${customerId}`} style={{ textDecoration: 'none' }}>
        <Card
          hover
          flush
          style={{ padding: '0 0 1px' }}
          flashMessage={
            isActionRequired && !reposit.tenancy.completedAt ? (
              <CardMessage
                title="You have outstanding actions"
                type="primary"
                cta={
                  <NavLink to={`checkout/${order.id}/${customerId}`} style={{ textDecoration: 'none' }}>
                    <Button buttonType="white">Continue</Button>
                  </NavLink>
                }
              />
            ) : undefined
          }
        >
          <SummaryContainer>
            <ColRight>{StatusComponent}</ColRight>
            <ColLeft>
              {address ? (
                <Address>
                  <FirstLineAddress>{getAddressFirstLine(address)}</FirstLineAddress>
                  <TownPostcode>{address.postcode}</TownPostcode>
                </Address>
              ) : (
                <NoAddress>There isn't a property associated with this Reposit</NoAddress>
              )}
              <Meta>
                <PPM>
                  £{penceToPounds(tenancy.PPM)}
                  <span>pcm</span>
                </PPM>
                <TenancyDates>
                  <Label>Tenancy dates</Label>
                  <Dates>
                    {moment(tenancy.startDate).format('Do MMM YYYY')} - {moment(tenancy.endDate).format('Do MMM YYYY')}
                  </Dates>
                </TenancyDates>
              </Meta>
            </ColLeft>
          </SummaryContainer>
          <TenantList tenants={tenancy.tenants} rounded />
        </Card>
      </NavLink>
    </RepositSummaryWrapper>
  );
};

export default RepositSummary;

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { Formik, FormikProps } from 'formik';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { getBreakpoint } from '../../base/style';
import Button from '../Button';
import Checkbox from '../FormFields/Checkbox';
import Loading from '../Loading/index';
import RepositCard from '../Reposit/RepositCard';
import SecondaryPanel from '../SecondaryPanel';
import { P1, P2 } from '../Typography';

interface CheckoutFormProps {
  stripe?: any;
  warningMessage?: React.FC;
  isSubmitting: boolean;
  submitCard: (stripe: Stripe, cardNumberElement: StripeCardNumberElement) => void;
  buttonText?: string;
  redWarningMessage: boolean;
  showCheckbox: boolean;
}

const Inner = styled.div`
  padding: 0 36px;
`;

const WarningMessageContainer = styled(P2)<{ redWarningMessage: boolean }>`
  color: ${(props) => (props.redWarningMessage ? props.theme.colors.negative : props.theme.colors.body)};
  font-weight: bold;
  margin: 0 0 18px;
`;

const CardElementWrapper = styled.div`
  font-size: 14px;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.colors.body};
  padding: 12px 0 24px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    justify-content: space-between;
  }

  .StripeElement {
    border: 1px solid #eee;
    margin: 8px 0;
    background: #fff;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    color: ${(props) => props.theme.colors.body};
    height: 36px;
    width: 100%;
    font-family: ${(props) => props.theme.typography.face.secondary};
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    outline: none;
    padding: 9px 0 9px 16px;

    &::placeholder {
      color: #aaaaaa;
      font-size: 15px;
    }

    &:focus {
      box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px ${(props) => props.theme.colors.body};
    }
  }

  .StripeElement--invalid {
    border: 1px solid ${(props) => props.theme.colors.negative};
  }
`;

const PanelWithColor = styled(SecondaryPanel)`
  margin-bottom: 24px;
`;

const CardNumberField = styled.div`
  flex: 0 0 47%;
`;

const ExpiryField = styled.div`
  flex: 0 0 24%;
`;

const CVCField = styled.div`
  flex: 0 0 22%;
`;

interface CheckoutFormValues {
  accept?: boolean;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  warningMessage: WarningMessage,
  isSubmitting,
  submitCard: submitPayment,
  buttonText,
  redWarningMessage,
  showCheckbox,
}) => {
  let Schema = Yup.object();
  if (WarningMessage) {
    Schema.shape({
      accept: Yup.bool().oneOf([true]).required(),
    });
  }

  const stripe: Stripe | null = useStripe();
  const elements = useElements();

  // Stripe.js has not loaded yet.
  if (!stripe || !elements) {
    return <Loading />;
  }

  return (
    <Formik
      initialValues={{
        accept: WarningMessage ? false : undefined,
      }}
      validationSchema={Schema}
      onSubmit={() => {
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement) return;
        submitPayment(stripe, cardNumberElement);
      }}
    >
      {({ handleChange, handleSubmit, isValid, values }: FormikProps<CheckoutFormValues>) => {
        const isDisabled = WarningMessage && showCheckbox ? !isValid || isSubmitting : isSubmitting;
        return (
          <form
            onSubmit={(e) => {
              if (isDisabled) return null;
              handleSubmit(e);
            }}
          >
            <RepositCard title="Payment Information" flush>
              <Inner>
                <P1>Please enter your payment details, your card will be saved to your account for future payments.</P1>
                <CardElementWrapper>
                  <CardNumberField>
                    Card Number
                    <CardNumberElement />
                  </CardNumberField>
                  <ExpiryField>
                    Expiry
                    <CardExpiryElement />
                  </ExpiryField>
                  <CVCField>
                    CVC
                    <CardCvcElement />
                  </CVCField>
                </CardElementWrapper>
              </Inner>
              <Fragment>
                {WarningMessage && (
                  <PanelWithColor style={{ background: redWarningMessage ? '#ffe6e6' : '' }}>
                    <WarningMessageContainer redWarningMessage={redWarningMessage}>
                      <WarningMessage />
                    </WarningMessageContainer>

                    {showCheckbox ? (
                      <Checkbox
                        name="accept"
                        label="I authorise payment to be taken"
                        isChecked={values.accept || false}
                        onChange={handleChange}
                      />
                    ) : null}
                  </PanelWithColor>
                )}
              </Fragment>
              <Inner style={{ paddingBottom: 24, paddingTop: 10 }}>
                <Button type="submit" disabled={isDisabled}>
                  {buttonText || `confirm and pay now`}
                </Button>
              </Inner>
            </RepositCard>
          </form>
        );
      }}
    </Formik>
  );
};

export default CheckoutForm;

import React, { useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import PageTitle from '../../../../components/PageTitle/index';
import Card from '../../../../components/Card/index';
import styled from 'styled-components';
import { P1 } from '../../../../components/Typography/index';
import Button from '../../../../components/Button/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  signRepositRequested,
  SIGN_REPOSIT_STORE_KEY,
} from '../../../../redux/order-customer-actions/order-customer-actions.actions';
import { getCurrentOrderCustomer } from '../../../../redux/order/order.selectors';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import { FullPageLoader } from '../../../../components/Loading/index';
import { getOrderCustomerActionById } from '../../../../redux/order-customer-actions/order-customer-actions.selectors';
import ToggleButton from '../../../../components/ToggleButton/index';
import { getBreakpoint } from '../../../../base/style';

interface SignProps {}

const Action = styled.div`
  margin-bottom: 120px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
`;

const ActionInfo = styled(P1)`
  font-size: 17px;
  margin: 0 20px 20px 0;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 20px;
  }
`;

const Link = styled(P1)`
  a {
    color: ${(props) => props.theme.colors.warning};
  }
`;

const ConfirmContainer = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const Sign: React.FC<SignProps> = () => {
  const [canSubmit, setCanSubmit] = useState<boolean | undefined>();
  const toggleCanSubmit = () => setCanSubmit(!canSubmit);
  const dispatch = useDispatch();
  const { order, customer, nextAction } = useSelector(getCurrentOrderCustomer);
  const signRepositLoadingSelector = createLoadingSelector([SIGN_REPOSIT_STORE_KEY]);
  const isSignRepositLoading = useSelector(signRepositLoadingSelector);
  const signTerms = () => dispatch(signRepositRequested(customer.id, order.id));
  // not ideal - but the type above thinks it could be undefined
  const action = useSelector(getOrderCustomerActionById(nextAction ? nextAction.orderCustomerActionIds[0] : ''));

  if (nextAction && !nextAction.actionable) {
    return (
      <Container>
        <Row>
          <Col lg={10} push={{ lg: 1 }}>
            <PageTitle>Waiting for other tenants...</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col lg={10} push={{ lg: 1 }}>
            {isSignRepositLoading ? (
              <FullPageLoader />
            ) : (
              <Card>
                <P1>
                  We're just waiting for the other tenants to confirm before you can complete the purchase of your Reposit. We'll
                  notify you via email and SMS when this is ready.
                </P1>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Agree to our Terms</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          {isSignRepositLoading ? (
            <FullPageLoader />
          ) : (
            <Card>
              <P1>
                Nearly there! We just need you to agree to our legal agreements which cover the points we mentioned in the
                previous step.
              </P1>
              <Link>
                <a href={action.details.termsLink} target="_blank" rel="noopener noreferrer">
                  Read our terms
                </a>
              </Link>
              <P1>
                If you are unsure of any points or have further questions please contact our support team via web chat or email.
              </P1>
              <ConfirmContainer>
                <ToggleButton name="confirm" onToggle={toggleCanSubmit} label="Accept terms" />
              </ConfirmContainer>
            </Card>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Action>
            <ActionInfo>Next: Payment</ActionInfo>
            <Button type="submit" disabled={isSignRepositLoading || !canSubmit} onClick={signTerms}>
              Continue
            </Button>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default Sign;

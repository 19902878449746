import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Card from '../../components/Card';
import { HEADER_HEIGHT } from '../../components/Library/SupplierOffer/ClaimItem/ClaimItemDropdown';
import { P1 } from '../../components/Typography';
import { PaymentType } from '../../redux/order-customer-actions/order-customer-actions.types';
import { penceToPounds } from '../../utils/currency';
import { Price, Text, Total } from './components';
import { ElementsForm } from './ElementsForm';
import Loading from '../../components/Loading/index';

interface PaymentContainerProps {
  fullWidth?: boolean;
  warningMessage?: React.FC;
  amount?: string;
  isSubmitting: boolean;
  submitCard: (stripe: Stripe, cardNumberElement: StripeCardNumberElement) => void;
  type: PaymentType;
  title: string;
}

export enum PaymentAmountType {
  SHARE = 'SHARE',
  REMAINING_BALANCE = 'REMAINING_BALANCE',
}

const TITLE_COLUMN_ID = 'title_column';

const PaymentContainer: React.FC<PaymentContainerProps> = ({
  fullWidth,
  warningMessage,
  amount,
  isSubmitting,
  submitCard,
  title,
}) => {
  const scrollToPaymentSection = () => {
    const el = document.getElementById(TITLE_COLUMN_ID);
    if (el) {
      const y = el.getBoundingClientRect().top + window.pageYOffset - HEADER_HEIGHT;
      setTimeout(() => {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 300);
    }
  };

  useEffect(() => {
    scrollToPaymentSection();
  }, []);
  return (
    <Container id={TITLE_COLUMN_ID}>
      {isSubmitting ? <Loading /> : null}
      <Row style={{ display: isSubmitting ? 'none' : 'flex' }}>
        <Col lg={fullWidth ? 6 : 4} push={{ lg: fullWidth ? 0 : 1 }}>
          <Card flush style={{ padding: 0 }}>
            <Fragment>
              <Text>{title}</Text>
              <Total>
                <Price>£{amount && penceToPounds(+amount)}</Price>
                <P1>payable today</P1>
              </Total>
            </Fragment>
          </Card>
        </Col>
        <Col lg={6} push={{ lg: fullWidth ? 0 : 1 }}>
          <ElementsForm
            warningMessage={warningMessage}
            isSubmitting={isSubmitting}
            submitCard={submitCard}
            buttonText={'Confirm and pay now'}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentContainer;

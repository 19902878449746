import {
  ClaimDTOStatusEnum,
  OrderCustomerDTO,
  OrderWithOrderCustomersDTOStatusIdEnum,
  TenancyOrderWithTenancyAndOrderCustomersDTO,
  TenancyOrderWithTenancyDTO,
  PaymentPlanDTOStatusEnum,
} from '@reposit/api-client';
import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import HammockImage from '../../assets/svg/hammock.svg';
import Megaphone from '../../assets/svg/megaphone.svg';
import { getBreakpoint } from '../../base/style';
import Filter from '../../components/Filter';
import { FullPageLoader } from '../../components/Loading/index';
import RepositList from '../../components/Reposit/RepositList';
import { Caption, Header2, Header4 } from '../../components/Typography';
import { RepositFilter } from '../../constants/reposit';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import { getCustomerById } from '../../redux/entities/entities.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  fetchTenancyAnniversariesRequested,
  fetchTenancyOrdersRequested,
  FETCH_TENANCY_ANNIVERSARIES_STORE_KEY,
  FETCH_TENANCY_ORDERS_STORE_KEY,
  FETCH_ORDER_CUSTOMER_STORE_KEY,
} from '../../redux/order/order.actions';
import {
  getCurrentOrderCustomer,
  getFilteredTenancyOrders,
  getTenancyAnniversaries,
  getTenancyOrders,
} from '../../redux/order/order.selectors';
import { AppState } from '../../redux/root.reducer';
import { getPaymentPlanByClaimId } from '../../redux/payment-plan/payment-plan.selectors';

interface DashboardProps {}

const ContentHeader = styled.div`
  text-align: center;
`;

const Subtitle = styled(Caption)`
  max-width: 540px;
  margin: 0 auto;
`;

const filters = [
  {
    value: RepositFilter.ACTIVE,
    label: 'Active',
  },
  {
    value: RepositFilter.EXPIRED,
    label: 'Expired',
  },
];

const Title = styled(Header2)`
  color: ${(props) => props.theme.colors.negative};
  margin: 24px 0 1rem;
`;

const RelaxImage = styled.div`
  background: transparent url(${HammockImage}) no-repeat center center;
  background-size: 50%;
  height: 300px;
  margin: -20px 0 -36px;
  width: 100%;

  @media screen and (max-width: ${getBreakpoint('md')}) {
    height: 510px;
    margin: -110px 0 -36px;
    display: none;
  }
`;

const MegaphoneImage = styled.img`
  @media screen and (max-width: ${getBreakpoint('md')}) {
    display: none;
  }
`;

const NoRepositsWrapper = styled.div`
  padding: 96px 0 0;
  text-align: center;
`;

const StyledContainer = styled(Container)`
  @media screen and (min-width: ${getBreakpoint('md')}) {
    padding-top: 36;
  }
`;

const NoReposits: React.FC<{ status: string }> = ({ status }) => {
  let text = '';

  if (status === 'ACTIVE') text = 'You dont have any active Reposits.';
  if (status === 'EXPIRED') text = 'You dont have any expired Reposits.';

  return (
    <NoRepositsWrapper>
      <Header4>{text}</Header4>
    </NoRepositsWrapper>
  );
};

const useHasOutstandingRepositActions = (tenancyOrders: TenancyOrderWithTenancyAndOrderCustomersDTO[], customerId: string) => {
  const hasOutstandingRepositActions = tenancyOrders.some((tenancyOrder: TenancyOrderWithTenancyAndOrderCustomersDTO) => {
    let isActionRequired = false;
    const { order } = tenancyOrder;
    const { statusId } = order;

    const currentOrderCustomer = order.orderCustomers.find(
      (orderCustomer: OrderCustomerDTO) => orderCustomer.customer.id === customerId
    );

    if (statusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE) {
      isActionRequired = false;
    } else {
      isActionRequired = currentOrderCustomer ? !!currentOrderCustomer.nextAction : false;
    }

    return isActionRequired;
  });

  return hasOutstandingRepositActions;
};

const Dashboard: React.FC<DashboardProps> = (props) => {
  const dispatch = useDispatch();
  const customerId = useSelector(getCurrentCustomerId);
  const customer = useSelector(getCustomerById(customerId));
  const currentOrderCustomer = useSelector(getCurrentOrderCustomer);

  const loadingSelector = createLoadingSelector([
    FETCH_TENANCY_ORDERS_STORE_KEY,
    FETCH_TENANCY_ANNIVERSARIES_STORE_KEY,
    FETCH_ORDER_CUSTOMER_STORE_KEY,
  ]);
  const isLoading = useSelector(loadingSelector);
  const [filter, setFilter] = useState('ACTIVE');
  const tenancyOrders = useSelector(getTenancyOrders);
  const tenancyAnniversaries = useSelector(getTenancyAnniversaries);
  const filteredTenancyOrders = useSelector((state: AppState) => getFilteredTenancyOrders(state, filter));
  const hasOutstandingRepositActions = useHasOutstandingRepositActions(tenancyOrders, customerId);
  const allowedClaimStatuses = [
    ClaimDTOStatusEnum.AWAITINGRESPONDENTS,
    ClaimDTOStatusEnum.AWAITINGARBITRATION,
    ClaimDTOStatusEnum.AWAITINGARBITRATIONADMINFEE,
    ClaimDTOStatusEnum.ARBITRATED,
    ClaimDTOStatusEnum.MEDIATION,
    ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION,
  ];
  const tenancyWithClaim: TenancyOrderWithTenancyDTO = tenancyOrders.find((tenancyOrder: TenancyOrderWithTenancyDTO): boolean => {
    const claim = get(tenancyOrder, 'tenancy.checkout.claim', null);
    return claim && allowedClaimStatuses.indexOf(claim.status) !== -1;
  });

  const activeUnpaidTopUp = tenancyAnniversaries.find((tenancyAnniversary) => {
    if (tenancyAnniversary.order) {
      const { order } = tenancyAnniversary;
      const isPending = order.statusId === OrderWithOrderCustomersDTOStatusIdEnum.PENDING;
      const orderCustomer = order.orderCustomers.find((oc: OrderCustomerDTO) => {
        return oc.customer.id === customerId;
      });
      const hasUnpaidAction =
        orderCustomer && orderCustomer.actions ? orderCustomer.actions.find((action) => !action.completedAt) : false;
      if (isPending && hasUnpaidAction) {
        return true;
      }
    }
    return false;
  });

  const claimId = get(tenancyWithClaim, 'tenancy.checkout.claim.id', null);
  const arbitration = get(tenancyWithClaim, 'tenancy.checkout.claim.arbitration', null);
  const paymentPlan = useSelector(getPaymentPlanByClaimId(claimId));

  useEffect(() => {
    dispatch(fetchTenancyOrdersRequested());
    dispatch(fetchTenancyAnniversariesRequested());
  }, [dispatch]);

  if (isLoading) return <FullPageLoader />;
  const hasPaymentPlan =
    paymentPlan &&
    currentOrderCustomer &&
    paymentPlan.orderCustomerId === currentOrderCustomer.id &&
    paymentPlan.status !== PaymentPlanDTOStatusEnum.DEFAULT;

  if (paymentPlan && hasPaymentPlan) return <Redirect to={`payment-plan/${paymentPlan.id}`} />;

  if (arbitration && !hasOutstandingRepositActions) return <Redirect to={`arbitrations/${arbitration.id}`} />;

  // force the user through the claim flow first
  if (claimId && !hasOutstandingRepositActions) return <Redirect to={`claims/${claimId}`} />;

  // force the user through the top-up fee payment flow first
  if (activeUnpaidTopUp && activeUnpaidTopUp.order) {
    return <Redirect to={`annual-fee/${activeUnpaidTopUp.order.id}/${customerId}`} />;
  }

  const shouldRedirectToAddPaymentMethod = customer && (customer.hasDuplicatePaymentMethod || customer.requiresNewPaymentMethod);

  if (shouldRedirectToAddPaymentMethod) {
    return <Redirect to={`add-payment-method`} />;
  }

  return (
    <StyledContainer>
      <Row>
        <Col sm={12}>
          {hasOutstandingRepositActions ? (
            <Fragment>
              <Row>
                <Col lg={4} style={{ textAlign: 'center' }}>
                  <MegaphoneImage src={Megaphone} width="200" alt="megaphone" />
                </Col>
                <Col lg={8}>
                  <Title>You have outstanding actions</Title>
                  <Caption>There are actions required on your Reposits. Please complete them.</Caption>
                </Col>
              </Row>
            </Fragment>
          ) : (
            <ContentHeader>
              <Header2>Sit back and relax</Header2>
              <Subtitle>There’s nothing for you to do right now. We will be in touch if anything changes</Subtitle>
              <RelaxImage />
            </ContentHeader>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div style={{ marginTop: 36 }}>
            <Filter onSelect={(filter) => setFilter(filter)} options={filters} label="Filter by status" selected={filter} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {filteredTenancyOrders.length ? <RepositList reposits={filteredTenancyOrders} /> : <NoReposits status={filter} />}
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Dashboard;

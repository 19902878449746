import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';

export interface OrderCustomerActionsState {
  paymentIntentSecret: string;
  claimPaymentIntentSecret: string;
  isPolling: boolean;
}

export interface OrderActionRepositPayload {
  orderId: string;
  customerId: string;
  paymentType?: OrderCustomerActionDTOTypeIdEnum;
  data?: any;
}

export enum PaymentType {
  REPOSIT = 'REPOSIT',
  CLAIM = 'CLAIM',
  ARBITRATION_FEE = 'ARBITRATION_FEE',
  ARBITRATION = 'ARBITRATION',
  TOP_UP = 'TOP_UP',
  CLAIM_PROPOSAL = 'CLAIM_PROPOSAL',
}

export interface PayPayload {
  stripe: Stripe;
  cardNumberElement: StripeCardNumberElement;
  paymentIntentSecret: string;
  type: PaymentType;
}

export interface PollPaymentSuccessPayload {
  redirectUrl: string;
  paymentIntentId: string;
}

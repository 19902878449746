import moment from 'moment';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import Card from '../../../components/Card';
import { FullPageLoader } from '../../../components/Loading';
import RepositCard from '../../../components/Reposit/RepositCard';
import { Header3, P1, P2 } from '../../../components/Typography';
import { PaymentPlanEntity } from '../../../redux/entities/entities.types';
import { penceToPounds } from '../../../utils/common.utils';
import { PaymentSchedule } from './PaymentSchedule';

const PaymentPlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const PaymentPlanSectionText = styled(P2)<{ color: 'orange' | 'green' }>`
  margin-left: 16px;
  font-weight: bold;
  color: ${(props) => (props.color === 'orange' ? props.theme.colors.warning : props.theme.colors.positive)};
`;

interface SummaryProps {
  paymentPlan: PaymentPlanEntity;
}

const Summary: React.FC<SummaryProps> = ({ paymentPlan }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!paymentPlan) return <FullPageLoader />;

  const { instalments } = paymentPlan;

  const firstInstalment = instalments && instalments[0];
  const lastInstalment = instalments && instalments[instalments.length - 1];

  const firstInstalmentDueDate = firstInstalment ? moment(firstInstalment?.dueDate).format('Do MMM YYYY') : '';
  const lastInstalmentDueDate = lastInstalment ? moment(lastInstalment.dueDate).format('Do MMM YYYY') : '';

  return (
    <>
      <RepositCard
        title="Setting up your plan"
        tooltip="Please be aware that failure to complete all three confirmation steps within 48 hours of issuance will render the payment plan offer void. If you do not activate the payment plan within this timeframe, your outstanding balance will become immediately payable in full. To receive a new payment plan, kindly reach out to our team for reissuance."
      >
        <P2>
          Please be advised that the confirmation of your payment plan is contingent upon the successful completion of all three
          sections.
        </P2>
        <PaymentPlanSectionText color="green">Step 1: Confirm you agree to the plan</PaymentPlanSectionText>
        <PaymentPlanSectionText color="orange">Step 2: Confirm your current address</PaymentPlanSectionText>
        <PaymentPlanSectionText color="orange">Step 3: Enter your payment details</PaymentPlanSectionText>
      </RepositCard>
      <Card>
        <Row>
          <Col lg={12}>
            <P1>
              Your payment plan instalments will be automatically charged to the payment details you provide on the days shown
              below. Please review the payment plan information below to make sure you are happy with the agreed process, and
              contact us on hello@reposit.co.uk if there are any issues.
            </P1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <PaymentPlanInfo>
              <Header3>Your Outstanding Balance</Header3>
              <P1>£{penceToPounds(paymentPlan.amount)}</P1>
            </PaymentPlanInfo>
          </Col>
          <Col xs={12} md={6}>
            <PaymentPlanInfo>
              <Header3>Number of Payment Instalments</Header3>
              <P1>{instalments ? instalments.length : ''}</P1>
            </PaymentPlanInfo>
          </Col>
          <Col xs={12} md={6}>
            <PaymentPlanInfo>
              <Header3>First instalment</Header3>
              <P1>{firstInstalmentDueDate}</P1>
            </PaymentPlanInfo>
          </Col>
          <Col xs={12} md={6}>
            <PaymentPlanInfo>
              <Header3>Last instalment</Header3>
              <P1>{lastInstalmentDueDate}</P1>
            </PaymentPlanInfo>
          </Col>
        </Row>
        <Row>
          <Col>
            <Header3 style={{ marginBottom: 15, marginTop: 15 }}>Schedule</Header3>
            {instalments ? <PaymentSchedule instalments={instalments} showStatus={false} expandable /> : null}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Summary;

import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';
import { setClaimResponseConfirmModal } from '../../../redux/claim/claim.actions';
import Button from '../../Button/index';
import Loading from '../../Loading/index';
import Modal from '../../Modal/index';
import { Header3, P2 } from '../../Typography/index';

const ModalAction = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }

  @media screen and (max-width: ${getBreakpoint('sm')}) {
    button:nth-child(2) {
      margin: 10px 6px;
    }
  }
`;

interface DeclineClaimModalProps {
  onSubmit: () => void;
  isLoading: boolean;
}

const DeclineClaimModal: React.FC<DeclineClaimModalProps> = ({ onSubmit, isLoading }) => {
  const dispatch = useDispatch();

  return (
    <Modal onDismiss={() => dispatch(setClaimResponseConfirmModal(null))}>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Header3 style={{ marginBottom: 6 }}>Proceed to Independent Dispute Resolution?</Header3>
          <P2 style={{ marginBottom: 6 }} bold>
            To continue, you will be required to pay the dispute fee of £60.
          </P2>
          <P2 style={{ marginBottom: 6 }}>
            If the arbitration result is allocated wholly in the landlord’s favour, you will not be refunded the £60 charge. If
            the result finds you are required to pay a lesser amount or none at all, the £60 charge will be refunded back to you
            on the same date the result is issued. Please note that if the arbitration result is a lesser amount, you will still
            be required to pay for the charges as you are still liable.
          </P2>
          <P2 style={{ marginBottom: 24 }} bold>
            You must pay the dispute fee for this dispute to be considered. If payment is not made, the charges will be
            automatically accepted and you will be liable for the charges in full.
          </P2>
          <ModalAction>
            <Button buttonType="secondary" noArrow={true} onClick={() => dispatch(setClaimResponseConfirmModal(null))}>
              No, return to form
            </Button>
            <Button onClick={onSubmit}>Yes</Button>
          </ModalAction>
        </Fragment>
      )}
    </Modal>
  );
};

export default DeclineClaimModal;

import React, { Fragment } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { appConfig } from './appConfig';
import { MainContent } from './components/Common';
import Header from './components/Header';
import { PrivateRoute } from './components/PrivateRoute';
import { AddPaymentMethod } from './containers/AddPaymentMethod/index';
import { AddPaymentMethodSuccess } from './containers/AddPaymentMethodSuccess/index';
import { Arbitration } from './containers/ArbitrationOutcome/ArbitrationOutcome';
import ArbitrationPaymentSuccess from './containers/ArbitrationPaymentSuccess';
import { Login } from './containers/Auth/Login/login';
import { ResetPassword } from './containers/Auth/ResetPassword/reset-password';
import { ResetPasswordRequest } from './containers/Auth/ResetPasswordRequest/reset-password-request';
import { SetPassword } from './containers/Auth/SetPassword/set-password';
import AuthenticateView from './containers/Authenticate';
import Checkout from './containers/Checkout';
import Finish from './containers/Checkout/Sections/Finish/index';
import Claim from './containers/Claim';
import ClaimPaymentSuccess from './containers/ClaimPaymentSuccess/ClaimPaymentSuccess';
import ClaimProposalAuthorisationSuccess from './containers/ClaimProposalPaymentSuccess';
import Dashboard from './containers/Dashboard';
import LoginView from './containers/Login';
import { Maintenance } from './containers/Maintenance/maintenance';
import NotFoundView from './containers/NotFound/index';
import PaymentPlan from './containers/PaymentPlan';
import { TenantVacated } from './containers/TenantVacated/tenant-vacated';
import TopUpFeePayment from './containers/TopUpFeePayment/index';
import TopUpFeePaymentSuccess from './containers/TopUpFeePaymentSuccess/index';

interface RouteDefinition {
  path: string;
  exact: boolean;
  mobileMenu: boolean;
  isFullWidth: boolean;
  nav?: () => JSX.Element | null;
  header?: () => JSX.Element | null;
  main: (props?: any) => JSX.Element;
}

const routes: RouteDefinition[] = [
  {
    path: '/',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <Dashboard />,
  },
  {
    path: '/checkout/:orderId/:customerId/finish',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <Finish {...props} />,
  },
  {
    path: '/checkout/:orderId/:customerId',
    exact: false,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <Checkout {...props} />,
  },
  {
    path: '/annual-fee/:orderId/:customerId',
    exact: false,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <TopUpFeePayment {...props} />,
  },
  {
    path: '/annual-fee/payment-success',
    exact: false,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <TopUpFeePaymentSuccess {...props} />,
  },
  {
    path: '/claims/payment-success',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <ClaimPaymentSuccess />,
  },
  {
    path: '/arbitrations/payment-success',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <ClaimPaymentSuccess />,
  },
  {
    path: '/claims/proposal-payment-success',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <ClaimProposalAuthorisationSuccess />,
  },
  {
    path: '/claims/arbitration-payment-success',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <ArbitrationPaymentSuccess />,
  },
  {
    path: '/claims/:claimId',
    exact: false,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <Claim {...props} />,
  },
  {
    path: '/arbitrations/:arbitrationId',
    exact: false,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <Arbitration {...props} />,
  },
  {
    path: '/login',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => null,
    header: () => null,
    main: () => <LoginView />,
  },
  {
    path: '/add-payment-method',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: (props: any) => <AddPaymentMethod {...props} />,
  },
  {
    path: '/add-payment-method-success',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    header: () => <Header />,
    main: () => <AddPaymentMethodSuccess />,
  },
  {
    path: '/authenticate',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => null,
    header: () => null,
    main: (props: any) => <AuthenticateView {...props} />,
  },
  {
    path: '/auth/login',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <Login />,
  },
  {
    path: '/auth/reset-password-request',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <ResetPasswordRequest />,
  },
  {
    path: '/auth/reset-password',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <ResetPassword />,
  },
  {
    path: '/auth/set-password',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <SetPassword />,
  },
  {
    path: '/tenant-vacated',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => <Header notAuthorised />,
    main: (props: any) => <TenantVacated />,
  },
  {
    path: '/payment-plan/:paymentPlanId',
    exact: false,
    mobileMenu: true,
    isFullWidth: true,
    nav: () => null,
    header: () => <Header />,
    main: (props: any) => <PaymentPlan {...props} />,
  },
  {
    path: '*',
    exact: false,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <NotFoundView {...props} />,
  },
];

const isPublicRoute = (path: string) => {
  return (
    path === '/login' ||
    path === '/authenticate' ||
    path === '/error' ||
    path === '/auth/login' ||
    path === '/auth/reset-password-request' ||
    path === '/auth/reset-password' ||
    path === '/auth/set-password' ||
    path === '/tenant-vacated'
  );
};

const Routes: React.FC<any> = () => {
  const location = useLocation();
  const isAuth = location.pathname.includes('/auth/');
  if (appConfig.inMaintenanceMode) {
    return <Maintenance />;
  }

  return (
    <Fragment>
      <Switch>
        {routes.map((route, index) => {
          const props = {
            key: index,
            path: route.path,
            exact: route.exact,
            component: route.header,
          };
          if (isPublicRoute(route.path)) {
            return <Route {...props} />;
          }
          return <PrivateRoute {...props} />;
        })}
      </Switch>
      <MainContent isAuth={isAuth}>
        <Switch>
          {routes.map((route, index) => {
            const props = {
              key: index,
              path: route.path,
              exact: route.exact,
              component: route.main,
            };
            if (isPublicRoute(route.path)) {
              return <Route {...props} />;
            }
            return <PrivateRoute {...props} />;
          })}
        </Switch>
      </MainContent>
    </Fragment>
  );
};

export default Routes;

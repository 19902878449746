import { PaymentPlanInstalmentDTOStatusEnum } from '@reposit/api-client/dist';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SlideDown from 'react-slidedown';
import styled, { css } from 'styled-components';
import { useAppDispatch } from '../../..';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import FlashMessage from '../../../components/FlashMessage';
import { FullPageLoader } from '../../../components/Loading';
import { Header3, P1, P3 } from '../../../components/Typography';
import { PaymentPlanEntity } from '../../../redux/entities/entities.types';
import { clearAmountRemaining } from '../../../redux/order/order.actions';
import { getOrderAmountRemaining } from '../../../redux/order/order.selectors';
import { fetchAmountRemainingForPaymentPlanOrderThunk } from '../../../redux/order/order.thunk';
import { PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_STORE_KEY } from '../../../redux/payment-plan-actions/payment-plan-actions.actions';
import { penceToPounds } from '../../../utils/common.utils';
import { useFlashMessage } from '../../FlashMessage';
import { PaymentSchedule } from './PaymentSchedule';

const LONG_TIMEOUT = 8000;

const PaymentPlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ExpanderTextStyles = css`
  color: ${(props) => props.theme.colors.primaryBrand};
  cursor: pointer;
  display: block;
  display: flex;

  span {
    text-decoration: underline;
  }

  &:after {
    content: '+';
    display: block;
    margin: 0 6px;
  }
`;

export const ExpanderText = styled(P3)`
  ${ExpanderTextStyles}
`;

export const StatusIcon = styled.i<{ icon?: string }>`
  background: ${(props) => (props.icon ? `url(${props.icon}) no-repeat center center` : 'none')};
  background-size: 25px;
  border-radius: 48px;
  display: block;
  height: 25px;
  width: 25px;
`;

interface DashboardProps {
  paymentPlan: PaymentPlanEntity;
}

const PaymentPlanDashboard: React.FC<DashboardProps> = ({ paymentPlan }) => {
  const dispatch = useAppDispatch();
  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(false);
  const ppOrderAmountRemaining = useSelector(getOrderAmountRemaining);
  const paymentPlanOrder = paymentPlan.orderCustomer.order;

  const toggleViewSchedule = () => {
    setIsSliderOpen(!isSliderOpen);
  };
  useEffect(() => {
    // TODO: NEED TO CLEAR THIS FROM THE STORE ON UNMOUNT
    if (paymentPlanOrder && !ppOrderAmountRemaining) {
      dispatch(fetchAmountRemainingForPaymentPlanOrderThunk({ orderId: paymentPlanOrder.id }));
    }
    return function cleanUp() {
      if (ppOrderAmountRemaining) {
        dispatch(clearAmountRemaining());
      }
    };
  }, [paymentPlanOrder, dispatch, ppOrderAmountRemaining]);

  const { instalments } = paymentPlan;
  const paidInstalments = instalments && instalments.filter((i) => i.status === PaymentPlanInstalmentDTOStatusEnum.PAID);
  const totalPaid = paidInstalments && paidInstalments.reduce((acc, instalment) => acc + instalment.amount, 0);

  const nextInstalment = instalments && instalments.find((i) => i.status !== PaymentPlanInstalmentDTOStatusEnum.PAID);
  const lastInstalment = instalments && instalments[instalments.length - 1];

  const nextInstalmentDueDate = nextInstalment ? moment(nextInstalment?.dueDate).format('Do MMM YYYY') : '';
  const lastInstalmentDueDate = lastInstalment ? moment(lastInstalment.dueDate).format('Do MMM YYYY') : '';

  const [flashMessage, onDismissFlashMessage] = useFlashMessage([PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_STORE_KEY]);

  if (!paymentPlan || !instalments || !ppOrderAmountRemaining) return <FullPageLoader />;

  return (
    <>
      {flashMessage ? (
        <FlashMessage payload={flashMessage} onDismiss={onDismissFlashMessage} timeRemaining={LONG_TIMEOUT} />
      ) : null}
      <Card>
        <Row>
          <Col xs={12} md={6}>
            <PaymentPlanInfo>
              <Header3>Outstanding balance</Header3>
              <P1>
                £{penceToPounds(ppOrderAmountRemaining)} ({paidInstalments && paidInstalments.length}/
                {instalments && instalments.length} paid)
              </P1>
            </PaymentPlanInfo>
          </Col>
          <Col xs={12} md={6}>
            <PaymentPlanInfo>
              <Header3>Total paid</Header3>
              <P1>£{totalPaid && penceToPounds(totalPaid)}</P1>
            </PaymentPlanInfo>
          </Col>
          <Col xs={12} md={6}>
            <PaymentPlanInfo>
              <Header3>Next instalment</Header3>
              <P1>{nextInstalmentDueDate}</P1>
            </PaymentPlanInfo>
          </Col>
          <Col xs={12} md={6}>
            <PaymentPlanInfo>
              <Header3>Last instalment</Header3>
              <P1>{lastInstalmentDueDate}</P1>
            </PaymentPlanInfo>
          </Col>
        </Row>
        <Row>
          <Col>
            <ExpanderText onClick={toggleViewSchedule}>
              <span>{isSliderOpen ? 'Hide' : 'Show payment plan schedule'}</span>
            </ExpanderText>
            <SlideDown closed={!isSliderOpen} className={`evidence-slider`}>
              <PaymentSchedule instalments={instalments} showStatus />
            </SlideDown>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonContainer>
              <NavLink to={`/payment-plan/${paymentPlan.id}/manual-payment`}>
                <Button buttonType="primary">Pay remaining balance</Button>
              </NavLink>
            </ButtonContainer>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default PaymentPlanDashboard;

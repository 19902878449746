import { UpdateAboutYouAttributesDTO, OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import { PayPayload, PollPaymentSuccessPayload } from './order-customer-actions.types';

export const UPDATE_ABOUT_YOU_STORE_KEY = 'ORDER_CUSTOMER_ACTIONS/UPDATE_ABOUT_YOU';
export const UPDATE_ABOUT_YOU_API_REQUESTED = `ORDER_CUSTOMER_ACTIONS/UPDATE_ABOUT_YOU_API_REQUESTED`;
export const UPDATE_ABOUT_YOU_API_SUCCESS = 'ORDER_CUSTOMER_ACTIONS/UPDATE_ABOUT_YOU_API_SUCCESS';
export const UPDATE_ABOUT_YOU_API_FAILED = 'ORDER_CUSTOMER_ACTIONS/UPDATE_ABOUT_YOU_API_FAILED';

export const UPDATE_ADDRESS_HISTORY_STORE_KEY = 'ORDER_CUSTOMER_ACTIONS/UPDATE_ADDRESS_HISTORY';
export const UPDATE_ADDRESS_HISTORY_API_REQUESTED = `ORDER_CUSTOMER_ACTIONS/UPDATE_ADDRESS_HISTORY_API_REQUESTED`;
export const UPDATE_ADDRESS_HISTORY_API_SUCCESS = 'ORDER_CUSTOMER_ACTIONS/UPDATE_ADDRESS_HISTORY_API_SUCCESS';
export const UPDATE_ADDRESS_HISTORY_API_FAILED = 'ORDER_CUSTOMER_ACTIONS/UPDATE_ADDRESS_HISTORY_API_FAILED';

export const CONFIRM_REPOSIT_STORE_KEY = 'ORDER_CUSTOMER_ACTIONS/CONFIRM_REPOSIT';
export const CONFIRM_REPOSIT_API_REQUESTED = `ORDER_CUSTOMER_ACTIONS/CONFIRM_REPOSIT_API_REQUESTED`;
export const CONFIRM_REPOSIT_API_SUCCESS = 'ORDER_CUSTOMER_ACTIONS/CONFIRM_REPOSIT_API_SUCCESS';
export const CONFIRM_REPOSIT_API_FAILED = 'ORDER_CUSTOMER_ACTIONS/CONFIRM_REPOSIT_API_FAILED';

export const SIGN_REPOSIT_STORE_KEY = 'ORDER_CUSTOMER_ACTIONS/SIGN_REPOSIT';
export const SIGN_REPOSIT_API_REQUESTED = 'ORDER_CUSTOMER_ACTIONS/SIGN_REPOSIT_API_REQUESTED';
export const SIGN_REPOSIT_API_SUCCESS = 'ORDER_CUSTOMER_ACTIONS/SIGN_REPOSIT_API_SUCCESS';
export const SIGN_REPOSIT_API_FAILED = 'ORDER_CUSTOMER_ACTIONS/SIGN_REPOSIT_API_FAILED';

export const GET_PAYMENT_INTENT_STORE_KEY = 'ORDER_CUSTOMER_ACTIONS/GET_PAYMENT_INTENT';
export const GET_PAYMENT_INTENT_API_REQUESTED = 'ORDER_CUSTOMER_ACTIONS/GET_PAYMENT_INTENT_API_REQUESTED';
export const GET_PAYMENT_INTENT_API_SUCCESS = 'ORDER_CUSTOMER_ACTIONS/GET_PAYMENT_INTENT_API_SUCCESS';
export const GET_PAYMENT_INTENT_API_FAILED = 'ORDER_CUSTOMER_ACTIONS/GET_PAYMENT_INTENT_API_FAILED';

export const GET_CLAIM_PAYMENT_INTENT_STORE_KEY = 'ORDER_CUSTOMER_ACTIONS/GET_CLAIM_PAYMENT_INTENT';
export const GET_CLAIM_PAYMENT_INTENT_API_REQUESTED = 'ORDER_CUSTOMER_ACTIONS/GET_CLAIM_PAYMENT_INTENT_API_REQUESTED';
export const GET_CLAIM_PAYMENT_INTENT_API_SUCCESS = 'ORDER_CUSTOMER_ACTIONS/GET_CLAIM_PAYMENT_INTENT_API_SUCCESS';
export const GET_CLAIM_PAYMENT_INTENT_API_FAILED = 'ORDER_CUSTOMER_ACTIONS/GET_CLAIM_PAYMENT_INTENT_API_FAILED';

export const PAY_STORE_KEY = 'ORDER_CUSTOMER_ACTIONS/PAY';
export const PAY_API_REQUESTED = 'ORDER_CUSTOMER_ACTIONS/PAY_API_REQUESTED';
export const PAY_API_SUCCESS = 'ORDER_CUSTOMER_ACTIONS/PAY_API_SUCCESS';
export const PAY_API_FAILED = 'ORDER_CUSTOMER_ACTIONS/PAY_API_FAILED';

export const POLL_PAYMENT_SUCCESS_STORE_KEY = 'ORDER_CUSTOMER_ACTIONS/POLL_PAYMENT_SUCCESS';
export const POLL_PAYMENT_SUCCESS_API_REQUESTED = 'ORDER_CUSTOMER_ACTIONS/POLL_PAYMENT_SUCCESS_API_REQUSTED';
export const POLL_PAYMENT_SUCCESS_API_SUCCESS = 'ORDER_CUSTOMER_ACTIONS/POLL_PAYMENT_SUCCESS_API_SUCCESS';
export const POLL_PAYMENT_SUCCESS_API_FAILED = 'ORDER_CUSTOMER_ACTIONS/POLL_PAYMENT_SUCCESS_API_FAILED';
export const POLL_PAYMENT_SUCCESS_API_CANCELLED = 'ORDER_CUSTOMER_ACTIONS/POLL_PAYMENT_SUCCESS_API_CANCELLED';

export function pollPaymentSuccessRequested(payload: PollPaymentSuccessPayload) {
  return {
    type: POLL_PAYMENT_SUCCESS_API_REQUESTED,
    payload,
  } as const;
}

export function pollPaymentSuccessSuccess() {
  return {
    type: POLL_PAYMENT_SUCCESS_API_SUCCESS,
    payload: {},
  } as const;
}

export function pollPaymentSuccessFailed(error: string) {
  return {
    type: POLL_PAYMENT_SUCCESS_API_FAILED,
    payload: error,
  } as const;
}

export function pollPaymentSuccessCancelled() {
  return {
    type: POLL_PAYMENT_SUCCESS_API_CANCELLED,
  } as const;
}

export function confirmRepositRequested(customerId: string, orderId: string) {
  return {
    type: CONFIRM_REPOSIT_API_REQUESTED,
    payload: {
      customerId,
      orderId,
    },
  } as const;
}

export function confirmRepositSuccess() {
  return {
    type: CONFIRM_REPOSIT_API_SUCCESS,
  } as const;
}

export function confirmRepositFailed(error: string) {
  return {
    type: CONFIRM_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

export function signRepositRequested(customerId: string, orderId: string) {
  return {
    type: SIGN_REPOSIT_API_REQUESTED,
    payload: {
      customerId,
      orderId,
    },
  } as const;
}

export function signRepositSuccess() {
  return {
    type: SIGN_REPOSIT_API_SUCCESS,
  } as const;
}

export function signRepositFailed(error: string) {
  return {
    type: SIGN_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

export function getPaymentIntentRequested(customerId: string, orderId: string) {
  return {
    type: GET_PAYMENT_INTENT_API_REQUESTED,
    payload: {
      customerId,
      orderId,
    },
  } as const;
}

export function getPaymentIntentSuccess(paymentIntentSecret: string) {
  return {
    type: GET_PAYMENT_INTENT_API_SUCCESS,
    payload: {
      paymentIntentSecret,
    },
  } as const;
}

export function getPaymentIntentFailed(error: string) {
  return {
    type: GET_PAYMENT_INTENT_API_FAILED,
    payload: error,
  } as const;
}

export function getClaimPaymentIntentRequested(
  customerId: string,
  orderId: string,
  paymentType?: OrderCustomerActionDTOTypeIdEnum
) {
  return {
    type: GET_CLAIM_PAYMENT_INTENT_API_REQUESTED,
    payload: {
      customerId,
      orderId,
      paymentType,
    },
  } as const;
}

export function getClaimPaymentIntentSuccess(paymentIntentSecret: string) {
  return {
    type: GET_CLAIM_PAYMENT_INTENT_API_SUCCESS,
    payload: {
      paymentIntentSecret,
    },
  } as const;
}

export function getClaimPaymentIntentFailed(error: string) {
  return {
    type: GET_CLAIM_PAYMENT_INTENT_API_FAILED,
    payload: error,
  } as const;
}

export function updateAboutYouRequested(customerId: string, orderId: string, data: UpdateAboutYouAttributesDTO) {
  return {
    type: UPDATE_ABOUT_YOU_API_REQUESTED,
    payload: {
      customerId,
      orderId,
      data,
    },
  } as const;
}

export function updateAboutYouSuccess() {
  return {
    type: UPDATE_ABOUT_YOU_API_SUCCESS,
  } as const;
}

export function updateAboutYouFailed(error: string) {
  return {
    type: UPDATE_ABOUT_YOU_API_FAILED,
    payload: error,
  } as const;
}

export function payRequested(payload: PayPayload) {
  return {
    type: PAY_API_REQUESTED,
    payload,
  } as const;
}

export function paySuccess() {
  return {
    type: PAY_API_SUCCESS,
  } as const;
}

export function payFailed(error: string) {
  return {
    type: PAY_API_FAILED,
    payload: error,
  } as const;
}

export function updateAddressHistoryRequested(customerId: string, orderId: string) {
  return {
    type: UPDATE_ADDRESS_HISTORY_API_REQUESTED,
    payload: {
      customerId,
      orderId,
    },
  } as const;
}

export function updateAddressHistorySuccess() {
  return {
    type: UPDATE_ADDRESS_HISTORY_API_SUCCESS,
  } as const;
}

export function updateAddressHistoryFailed(error: string) {
  return {
    type: UPDATE_ADDRESS_HISTORY_API_FAILED,
    payload: error,
  } as const;
}

export type OrderCustomerActionsActionTypes = ReturnType<
  | typeof confirmRepositRequested
  | typeof confirmRepositSuccess
  | typeof confirmRepositFailed
  | typeof signRepositRequested
  | typeof signRepositSuccess
  | typeof signRepositFailed
  | typeof getPaymentIntentRequested
  | typeof getPaymentIntentSuccess
  | typeof getPaymentIntentFailed
  | typeof getClaimPaymentIntentRequested
  | typeof getClaimPaymentIntentSuccess
  | typeof getClaimPaymentIntentFailed
  | typeof payRequested
  | typeof paySuccess
  | typeof payFailed
  | typeof updateAboutYouRequested
  | typeof updateAboutYouSuccess
  | typeof updateAboutYouFailed
  | typeof updateAddressHistoryRequested
  | typeof updateAddressHistorySuccess
  | typeof updateAddressHistoryFailed
  | typeof pollPaymentSuccessRequested
  | typeof pollPaymentSuccessSuccess
  | typeof pollPaymentSuccessFailed
  | typeof pollPaymentSuccessCancelled
>;

import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';
import { setClaimResponseConfirmModal } from '../../../redux/claim/claim.actions';
import Button from '../../Button/index';
import Loading from '../../Loading/index';
import Modal from '../../Modal/index';
import { Header3, P2 } from '../../Typography/index';

const ModalAction = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }

  @media screen and (max-width: ${getBreakpoint('sm')}) {
    button:nth-child(2) {
      margin: 10px 6px;
    }
  }
`;

interface ApproveClaimModalProps {
  onSubmit: () => void;
  isLoading: boolean;
}

const ApproveClaimModal: React.FC<ApproveClaimModalProps> = ({ onSubmit, isLoading }) => {
  const dispatch = useDispatch();

  return (
    <Modal onDismiss={() => dispatch(setClaimResponseConfirmModal(null))}>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Header3 style={{ marginBottom: 6 }}>Accept end of tenancy charges</Header3>
          <P2 style={{ marginBottom: 24 }}>
            By accepting the charges raised by your letting agent/landlord, you will be required to settle them in full.
          </P2>
          <ModalAction>
            <Button buttonType="secondary" noArrow={true} onClick={() => dispatch(setClaimResponseConfirmModal(null))}>
              No, return to form
            </Button>
            <Button onClick={onSubmit}>Confirm</Button>
          </ModalAction>
        </Fragment>
      )}
    </Modal>
  );
};

export default ApproveClaimModal;

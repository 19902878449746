import { get } from 'lodash';
import { AppState } from '../root.reducer';

export const getTenancyById = (id: string) => (state: AppState) => get(state, `entities.tenancy.${id}`);

export const getTenantById = (id: string) => (state: AppState) => {
  return state.entities.tenant[id];
};

export const getCustomerById = (id: string) => (state: AppState) => {
  return state.entities.customer[id];
};

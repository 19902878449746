import {
  ClaimItemDTOTypeEnum,
  ClaimProposalDTOSourceEnum,
  ClaimProposalDTOStatusEnum,
  ClaimProposalDTOTypeEnum,
  CreateClaimItemProposalDTO,
  ClaimItemProposalDTOSettledSourceEnum,
} from '@reposit/api-client';
import { get, startCase, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Visible } from 'react-grid-system';
import SlideDown from 'react-slidedown';
import SyncIcon from '../../../../assets/svg/sync-alt.svg';
import GreenTick from '../../../../assets/svg/tick-green.svg';
import { useIsMobile } from '../../../../hooks/useViewport';
import { ClaimItemProposalEntity, DocumentEntity } from '../../../../redux/entities/entities.types';
import { Header3, Header4, P1, P2, P3 } from '../../../Typography/index';
import { ClaimItemDropdown } from './ClaimItemDropdown';
import {
  AgreeButton,
  Amount,
  DescriptionContainer,
  DisagreeButton,
  EditButton,
  EditButtonContainer,
  EvidenceChecklistP2,
  EvidenceChecklistP3,
  ItemInfoContainer,
  Panel,
  StatusIcon,
} from './components';

export interface ClaimItem {
  id: string;
  description: string;
  type: ClaimItemDTOTypeEnum;
  claimId: string;
  itemProposals: ItemProposal[];
  isSettled: boolean;
}

export interface ItemProposal {
  id: string;
  claimProposal: ClaimProposal;
  settled?: boolean;
  settledSource?: ClaimItemProposalDTOSettledSourceEnum;
  amount: number;
  explanation?: string;
  documents?: DocumentEntity[];
  createdAt: string;
  updatedAt: string;
}

export interface ClaimProposal {
  id: string;
  type: ClaimProposalDTOTypeEnum;
  round: number;
  source: ClaimProposalDTOSourceEnum;
  status: ClaimProposalDTOStatusEnum;
}

interface ClaimItemProps {
  claimItem: ClaimItem;
  canRespond: boolean;
  viewOnly?: boolean;
  createCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  updateCounterProposal: (payload: CreateClaimItemProposalDTO, id: string) => Promise<ClaimItemProposalEntity>;
  setOpenedClaimItem: (state: string | undefined) => void;
  hasGoneBack: boolean;
}

export enum ShowMoreType {
  EVIDENCE = 'EVIDENCE',
  FORM = 'FORM',
}

export enum ClaimItemState {
  INITIAL = 'INITIAL',
  VIEW_EVIDENCE = 'VIEW_EVIDENCE',
  ACCEPTED_TENANT_COUNTER = 'ACCEPTED_TENANT_COUNTER',
  REJECTED_TENANT_COUNTER = 'REJECTED_TENANT_COUNTER',
}

const ClaimItem: React.FC<ClaimItemProps> = ({
  claimItem,
  canRespond,
  viewOnly,
  createCounterProposal,
  updateCounterProposal,
  setOpenedClaimItem,
  hasGoneBack,
}) => {
  const [claimItemState, setClaimItemState] = useState<ClaimItemState>(ClaimItemState.INITIAL);
  const panelId = `panel_${claimItem.id}`;

  useEffect(() => {
    if (claimItemState === ClaimItemState.INITIAL) {
      setOpenedClaimItem(undefined);
    } else {
      setOpenedClaimItem(claimItem.id);
    }
  }, [claimItemState, claimItem, setOpenedClaimItem]);

  const tenantProposal = claimItem.itemProposals.find((ip) => ip.claimProposal.round === 2);
  const isTenantProposalDraft = !!(tenantProposal && tenantProposal.claimProposal.status === ClaimProposalDTOStatusEnum.DRAFT);
  // const isTenantDeciding = useSelector((state: AppState) => getIsTenantDeciding(state, claimItem.claimId));

  const tenantProposalAmount = get(tenantProposal, 'amount', undefined);
  const initialAgentProposal = claimItem.itemProposals.find((ip) => ip.claimProposal.round === 1) as ItemProposal;
  const initialAgentProposalAmount = get(initialAgentProposal, 'amount', undefined);
  const secondAgentProposal = claimItem.itemProposals.find((ip) => ip.claimProposal.round === 3);
  const secondAgentProposalAmount = get(secondAgentProposal, 'amount', undefined);
  const isSecondAgentProposalDraft = !!(
    secondAgentProposal && secondAgentProposal.claimProposal.status === ClaimProposalDTOStatusEnum.DRAFT
  );
  const latestAgentProposal = (isSecondAgentProposalDraft && secondAgentProposal) || initialAgentProposal;
  const toggleViewEvidence = () => {
    if (claimItemState === ClaimItemState.VIEW_EVIDENCE) {
      setClaimItemState(ClaimItemState.INITIAL);
    } else {
      setClaimItemState(ClaimItemState.VIEW_EVIDENCE);
    }
  };

  const getIcon = () => {
    if (hasGoneBack) {
      return undefined;
    }
    if (claimItem.isSettled) {
      return GreenTick;
    }
    if (tenantProposal) {
      if (tenantProposal.settled) {
        return GreenTick;
      }
      return SyncIcon;
    } else {
      return undefined;
    }
  };

  const icon = getIcon();

  const isSliderOpen =
    claimItemState === ClaimItemState.VIEW_EVIDENCE ||
    claimItemState === ClaimItemState.REJECTED_TENANT_COUNTER ||
    claimItemState === ClaimItemState.ACCEPTED_TENANT_COUNTER;

  const noActionTaken = claimItemState === ClaimItemState.INITIAL || claimItemState === ClaimItemState.VIEW_EVIDENCE;

  const acceptProposal = async () => {
    // creating a new item proposal with the same amount as the agent proposal
    await createCounterProposal({ claimItemId: claimItem.id, amount: latestAgentProposal.amount });
    setClaimItemState(ClaimItemState.INITIAL);
  };

  const isMobile = useIsMobile();

  const renderButtons = () => {
    const ButtonComponent = noActionTaken ? EditButton : DisagreeButton;
    if (canRespond) {
      // if (draftTenantProposal || proposal.settled) {
      if (tenantProposal) {
        return (
          <EditButtonContainer>
            <ButtonComponent
              noArrow={isMobile}
              buttonType={'secondary'}
              style={{ width: isMobile ? '25%' : undefined }}
              onClick={() => setClaimItemState(noActionTaken ? ClaimItemState.REJECTED_TENANT_COUNTER : ClaimItemState.INITIAL)}
            >
              {noActionTaken ? 'Edit' : 'Back'}
            </ButtonComponent>
          </EditButtonContainer>
        );
      } else {
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
            {claimItemState !== ClaimItemState.REJECTED_TENANT_COUNTER ? (
              <AgreeButton noArrow={isMobile} buttonType={'primary'} onClick={acceptProposal}>
                Agree
              </AgreeButton>
            ) : null}

            <DisagreeButton
              noArrow={isMobile}
              buttonType={'primary'}
              onClick={() => setClaimItemState(noActionTaken ? ClaimItemState.REJECTED_TENANT_COUNTER : ClaimItemState.INITIAL)}
              style={{
                width: noActionTaken ? '50%' : isMobile ? '100%' : undefined,
              }}
            >
              {noActionTaken ? 'Disagree' : 'Back'}
            </DisagreeButton>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const getAmountText = () => {
    if (claimItem.isSettled) {
      return 'Agreed settlement';
    }
    if (tenantProposal && (!secondAgentProposal || isSecondAgentProposalDraft) && !hasGoneBack) {
      if (tenantProposal.settled) {
        return 'Accepted proposal';
      } else {
        return 'Your proposal';
      }
    } else {
      return 'Landlord charges';
    }
  };

  const actionRequired =
    canRespond &&
    !isSliderOpen &&
    !isTenantProposalDraft &&
    !!(initialAgentProposal && !initialAgentProposal.settled) &&
    !viewOnly;

  const dropdownProps = {
    tenantProposal,
    initialAgentProposal,
    secondAgentProposal,
    claimItemState,
    canRespond,
    setClaimItemState,
    createCounterProposal,
    updateCounterProposal,
    claimItem,
    claimId: claimItem.claimId,
    acceptProposal,
    panelId,
  };

  const getAmount = () => {
    if (secondAgentProposalAmount !== null && secondAgentProposalAmount !== undefined && !isSecondAgentProposalDraft) {
      return secondAgentProposalAmount;
    }
    if (tenantProposalAmount !== null && tenantProposalAmount !== undefined && !hasGoneBack) {
      return tenantProposalAmount;
    }
    return initialAgentProposalAmount;
  };

  const amount = getAmount();

  return (
    <Panel id={panelId} actionRequired={actionRequired}>
      <Visible xs sm>
        <ItemInfoContainer column>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Header3 style={{ marginBottom: 0 }}>{claimItem.description}</Header3>
            {icon ? <StatusIcon icon={icon} style={{ marginLeft: '15px' }} /> : null}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
            <P1 style={{ marginBottom: 0, marginRight: 5, width: 150 }}>{getAmountText()}:</P1>
            {amount !== undefined && amount !== null ? <Amount amount={amount} /> : null}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 6 }}>
            <P2 style={{ marginBottom: 0, marginRight: 6 }}>{startCase(toLower(claimItem.type))}</P2>
            {claimItemState !== ClaimItemState.REJECTED_TENANT_COUNTER ? (
              <EvidenceChecklistP2 onClick={toggleViewEvidence}>
                <span>{claimItemState === ClaimItemState.VIEW_EVIDENCE ? 'Hide' : 'Show more'}</span>
              </EvidenceChecklistP2>
            ) : null}
          </div>
          {!viewOnly && renderButtons()}
        </ItemInfoContainer>
      </Visible>
      <Visible md lg xl>
        <ItemInfoContainer column={false}>
          <DescriptionContainer>
            <Header4 style={{ marginBottom: 0 }}>{claimItem.description}</Header4>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
              <P3 style={{ marginBottom: 0, marginRight: 6 }}>{startCase(toLower(claimItem.type))}</P3>
              {claimItemState !== ClaimItemState.REJECTED_TENANT_COUNTER ? (
                <EvidenceChecklistP3 onClick={toggleViewEvidence}>
                  <span>{claimItemState === ClaimItemState.VIEW_EVIDENCE ? 'Hide' : 'Show more'}</span>
                </EvidenceChecklistP3>
              ) : null}
            </div>
          </DescriptionContainer>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <P3 style={{ marginBottom: 0, marginRight: 5 }}>{getAmountText()}:</P3>
              {amount !== undefined && amount !== null ? <Amount amount={amount} /> : null}
              {icon ? <StatusIcon icon={icon} style={{ marginLeft: '15px' }} /> : null}
            </div>
            {!viewOnly && renderButtons()}
          </div>
        </ItemInfoContainer>
      </Visible>
      <SlideDown closed={!isSliderOpen} className={`evidence-slider`}>
        <ClaimItemDropdown {...dropdownProps} />
      </SlideDown>
    </Panel>
  );
};
export default ClaimItem;

const webURL = 'https://staging.tenant.reposit.co.uk';
const clientId = 'p6chhI0O3K2jrPerKeUp3gtJy9DLMPgo';

export const appConfig = {
  links: {
    auth0: {
      logout: `https://reposit-staging.eu.auth0.com/v2/logout?client_id=${clientId}`,
      login: `https://reposit-staging.eu.auth0.com/authorize?response_type=token&client_id=${clientId}&redirect_uri=${webURL}/authenticate&audience=https://staging.api.com`,
    },
    local: {
      login: `${webURL}/login`,
    },
  },
  api: {
    basePath: 'https://staging.api.reposit.co.uk',
  },
  analytics: {
    trackers: [
      {
        name: 'staging',
        id: 'UA-81451405-6',
      },
    ],
  },
  stripe: {
    apiKey: 'pk_test_J0MDw0x6QrWhf4pLdKcVziuQ',
  },
  hubspot: {
    accountId: '9159844',
  },
  hotjar: {
    enabled: false,
    siteId: 0,
    hotjarVersion: 6,
  },
  inMaintenanceMode: false,
};

import { schema, Schema } from 'normalizr';
import {
  AddressDTO,
  LandlordDTO,
  PropertyDTO,
  UserDTO,
  TenantDTO,
  OrderDTO,
  OrganizationDTO,
  TenancyDTO,
  DocumentDTO,
  TenancyOrderWithTenancyDTO,
  OrderCustomerDTO,
  OrderCustomerActionDTO,
  ClaimDTO,
  CheckoutDTO,
  ClaimItemDTO,
  CustomerDTO,
  ReferenceDTO,
  CheckDTO,
  ClaimResponseDocumentDTO,
  OrganizationInviteDTO,
  ClaimRespondentDTO,
  TenancyAnniversaryDTO,
  ArbitrationDTO,
  ClaimProposalDTO,
  ClaimItemProposalDTO,
  ClaimItemDocumentDTO,
  PaymentPlanDTO,
  PaymentPlanActionDTO,
} from '@reposit/api-client';

export interface SchemaDefinition {
  arbitration: Schema<ArbitrationDTO>;
  arbitrations: Array<Schema<ArbitrationDTO>>;
  address: Schema<AddressDTO>;
  customer: Schema<CustomerDTO>;
  document: Schema<DocumentDTO>;
  landlord: Schema<LandlordDTO>;
  property: Schema<PropertyDTO>;
  user: Schema<UserDTO>;
  users: Array<Schema<UserDTO>>;
  tenant: Schema<TenantDTO>;
  order: Schema<OrderDTO>;
  organization: Schema<OrganizationDTO>;
  invite: Schema<OrganizationInviteDTO>;
  invites: Array<Schema<OrganizationInviteDTO>>;
  tenancy: Schema<TenancyDTO>;
  tenancyOrder: Schema<TenancyOrderWithTenancyDTO>;
  tenancyAnniversary: Schema<TenancyAnniversaryDTO>;
  tenancies: Array<Schema<TenancyDTO>>;
  tenancyOrders: Array<Schema<TenancyOrderWithTenancyDTO>>;
  tenancyAnniversaries: Array<Schema<TenancyAnniversaryDTO>>;
  properties: Array<Schema<PropertyDTO>>;
  addresses: Array<Schema<AddressDTO>>;
  orderCustomer: Schema<OrderCustomerDTO>;
  orderCustomerAction: Schema<OrderCustomerActionDTO>;
  claim: Schema<ClaimDTO>;
  claims: Array<Schema<ClaimDTO>>;
  claimProposal: Schema<ClaimProposalDTO>;
  claimItemProposal: Schema<ClaimItemProposalDTO>;
  claimItem: Schema<ClaimItemDTO>;
  claimItemDocument: Schema<ClaimItemDocumentDTO>;
  checkout: Schema<CheckoutDTO>;
  reference: Schema<ReferenceDTO>;
  check: Schema<CheckDTO>;
  checks: Array<Schema<CheckDTO>>;
  claimResponseDocument: Schema<ClaimResponseDocumentDTO>;
  claimResponseDocuments: Array<Schema<ClaimResponseDocumentDTO>>;
  claimRespondent: Schema<ClaimRespondentDTO>;
  paymentPlan: Schema<PaymentPlanDTO>;
  paymentPlanAction: Schema<PaymentPlanActionDTO>;
}

const SCHEMA: SchemaDefinition = {
  arbitration: {},
  arbitrations: [],
  address: {},
  customer: {},
  document: {},
  landlord: {},
  property: {},
  user: {},
  users: [],
  tenant: {},
  order: {},
  organization: {},
  invite: {},
  invites: [],
  tenancy: {},
  claim: {},
  claimProposal: {},
  claimItemProposal: {},
  claimItemDocument: {},
  tenancies: [],
  tenancyOrder: {},
  tenancyAnniversary: {},
  tenancyOrders: [],
  tenancyAnniversaries: [],
  properties: [],
  addresses: [],
  orderCustomer: {},
  orderCustomerAction: {},
  claims: [],
  claimItem: {},
  checkout: {},
  reference: {},
  check: {},
  checks: [],
  claimResponseDocument: {},
  claimResponseDocuments: [],
  claimRespondent: {},
  paymentPlan: {},
  paymentPlanAction: {},
};

SCHEMA.arbitration = new schema.Entity('arbitration');

SCHEMA.reference = new schema.Entity('reference');

SCHEMA.check = new schema.Entity('check');

SCHEMA.checks = [SCHEMA.check];

SCHEMA.customer = new schema.Entity('customer');

SCHEMA.checkout = new schema.Entity('checkout');

SCHEMA.address = new schema.Entity('address');

SCHEMA.document = new schema.Entity('document');

SCHEMA.orderCustomer = new schema.Entity('orderCustomer');

SCHEMA.orderCustomerAction = new schema.Entity('orderCustomerAction', {
  orderCustomer: SCHEMA.orderCustomer,
});

SCHEMA.tenancy = new schema.Entity('tenancy');

SCHEMA.claimItemDocument = new schema.Entity('claimItemDocument');

SCHEMA.claim = new schema.Entity('claim');

SCHEMA.claimItem = new schema.Entity('claimItem', {
  claim: SCHEMA.claim,
  claimItemDocuments: [SCHEMA.claimItemDocument],
});

SCHEMA.claimItemDocument.define({
  claimItem: SCHEMA.claimItem,
  document: SCHEMA.document,
});

SCHEMA.claimItemProposal = new schema.Entity('claimItemProposal');

SCHEMA.claimProposal = new schema.Entity('claimProposal', {
  claim: SCHEMA.claim,
  itemProposals: [SCHEMA.claimItemProposal],
});

SCHEMA.claimItemProposal.define({
  documents: [SCHEMA.claimItemDocument],
});

SCHEMA.organization = new schema.Entity('organization');

SCHEMA.landlord = new schema.Entity('landlord', {
  address: SCHEMA.address,
  organization: SCHEMA.organization,
});

SCHEMA.property = new schema.Entity('property', {
  address: SCHEMA.address,
  landlord: SCHEMA.landlord,
  organization: SCHEMA.organization,
});

SCHEMA.order = new schema.Entity('order', {
  orderCustomers: [SCHEMA.orderCustomer],
});

SCHEMA.user = new schema.Entity('user', {
  organizations: [SCHEMA.organization],
});

SCHEMA.users = [SCHEMA.user];

SCHEMA.invite = new schema.Entity('invite', {
  organization: SCHEMA.organization,
});

SCHEMA.invites = [SCHEMA.invite];

SCHEMA.claimResponseDocument = new schema.Entity('claimResponseDocument', {
  document: SCHEMA.document,
});
SCHEMA.claimResponseDocuments = [SCHEMA.claimResponseDocument];

SCHEMA.claimRespondent = new schema.Entity('claimRespondent', {});

SCHEMA.tenant = new schema.Entity('tenant', {
  user: SCHEMA.user,
});

SCHEMA.tenancyOrder = new schema.Entity('tenancyOrder', {
  tenancy: SCHEMA.tenancy,
  order: SCHEMA.order,
});

SCHEMA.tenancyAnniversary = new schema.Entity('tenancyAnniversary', {
  tenancy: SCHEMA.tenancy,
  order: SCHEMA.order,
});

SCHEMA.orderCustomer.define({
  order: SCHEMA.order,
  customer: SCHEMA.customer,
  actions: [SCHEMA.orderCustomerAction],
});

SCHEMA.checkout.define({
  tenancy: SCHEMA.tenancy,
  claim: SCHEMA.claim,
});

SCHEMA.paymentPlan = new schema.Entity('paymentPlan', {});

SCHEMA.paymentPlanAction = new schema.Entity('paymentPlanAction', {
  paymentPlan: SCHEMA.paymentPlan,
});

SCHEMA.claim.define({
  // TODO: NEED TO TYPE ALL OF THE RELATIONS INSIDE HERE
  checkout: SCHEMA.checkout,
  items: [SCHEMA.claimItem],
  respondents: [SCHEMA.claimRespondent],
  order: SCHEMA.order,
  arbitration: SCHEMA.arbitration,
  proposals: [SCHEMA.claimProposal],
  responseDocuments: [SCHEMA.claimResponseDocument],
  adjudicationFeeOrder: SCHEMA.order,
});

SCHEMA.arbitration.define({
  repaymentOrder: SCHEMA.order,
  documents: [SCHEMA.document],
});

SCHEMA.tenancy.define({
  orders: [SCHEMA.tenancyOrder],
  ANNIVERSARIES: [SCHEMA.tenancyAnniversaries],
  documents: [SCHEMA.document],
  property: SCHEMA.property,
  tenants: [SCHEMA.tenant],
  checkout: SCHEMA.checkout,
  organization: SCHEMA.organization,
});

SCHEMA.tenancies = [SCHEMA.tenancy];
SCHEMA.tenancyOrders = [SCHEMA.tenancyOrder];
SCHEMA.tenancyAnniversaries = [SCHEMA.tenancyAnniversary];
SCHEMA.properties = [SCHEMA.property];
SCHEMA.addresses = [SCHEMA.address];
SCHEMA.claims = [SCHEMA.claim];
SCHEMA.arbitrations = [SCHEMA.arbitration];

export default SCHEMA;

import { UsersApi, CustomersApi, CustomerDTO } from '@reposit/api-client';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createUsersApi, runSagaWithAuth, createCustomersApi } from '../utils/api.utils';
import {
  fetchMeFailed,
  fetchMeSuccess,
  FETCH_ME_API_REQUESTED,
  FETCH_CUSTOMER_API_REQUESTED,
  fetchCustomerSuccess,
  fetchCustomerFailed,
  ADD_PAYMENT_METHOD_API_REQUESTED,
  addPaymentMethodFailed,
  ADD_PAYMENT_METHOD_STORE_KEY,
  addPaymentMethodSuccess,
} from './account.actions';
import { logout } from '../auth/auth.actions';
import { AddPaymentMethodPayload } from './account.types';
import { PaymentMethod, StripeError } from '@stripe/stripe-js';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage/index';
import { push } from 'connected-react-router';

// ****************
// WORKERS
// ****************
export function* fetchMe() {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { data } = yield call(runSagaWithAuth(() => usersApi.me()));
    const { user } = data;
    const addresses = get(user.attributes, 'addresses', []);

    const addressesWithIds = addresses.map((address: any, i: number) => {
      address.id = `tmp_id_${i + 1}`;
      return address;
    });

    const userWithAddressIds = {
      ...user,
      attributes: {
        ...user.attributes,
        addresses: addressesWithIds,
      },
    };

    const id: string = yield syncEntitiesAndGetResults({ ...userWithAddressIds }, SCHEMA.user);
    yield put(fetchMeSuccess(id));
  } catch (e) {
    yield put(fetchMeFailed(get(e, 'response.data.message', e)));
    yield put(logout());
  }
}

export function* fetchCustomer() {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { data } = yield call(runSagaWithAuth(() => usersApi.getCustomer()));
    const id: string = yield syncEntitiesAndGetResults(data, SCHEMA.customer);
    yield put(fetchCustomerSuccess(id));
  } catch (e) {
    yield put(fetchCustomerFailed(get(e, 'response.data.message', e)));
    yield put(logout());
  }
}

export function* addPaymentMethod({ payload }: { type: string; payload: AddPaymentMethodPayload }) {
  const { stripe, cardNumberElement } = payload;
  try {
    const customersApi: CustomersApi = yield createCustomersApi();
    const {
      paymentMethod,
      error,
    }: {
      paymentMethod?: PaymentMethod | undefined;
      error?: StripeError | undefined;
    } = yield stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    if (!paymentMethod || error) {
      yield put(addPaymentMethodFailed(error && error.message ? error.message : 'An error has occured'));
      yield put<FlashMessagesActionTypes>(
        setFlashMessage({
          key: ADD_PAYMENT_METHOD_STORE_KEY,
          message: error && error.message ? error.message : 'An error has occured',
          state: FlashState.ERROR,
        })
      );
      return;
    }

    const { data }: { data: CustomerDTO } = yield call(runSagaWithAuth(() => customersApi.addPaymentMethod(paymentMethod.id)));
    yield syncEntitiesAndGetResults(data, SCHEMA.customer);
    yield put(addPaymentMethodSuccess());
    yield put(push('/add-payment-method-success'));
  } catch (e) {
    yield put(addPaymentMethodFailed(get(e, 'response.data.message', e)));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: ADD_PAYMENT_METHOD_STORE_KEY,
        message: get(e, 'response.data.message', e),
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchAccountSagas() {
  yield takeLatest(FETCH_ME_API_REQUESTED, fetchMe);
  yield takeLatest(FETCH_CUSTOMER_API_REQUESTED, fetchCustomer);
  yield takeLatest(ADD_PAYMENT_METHOD_API_REQUESTED, addPaymentMethod);
}

import React, { useEffect, useState } from 'react';
import { get } from 'lodash';

import PaymentContainer from '../Payment/index';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import {
  getClaimPaymentIntentRequested,
  PAY_STORE_KEY,
  GET_CLAIM_PAYMENT_INTENT_STORE_KEY,
  payRequested,
} from '../../redux/order-customer-actions/order-customer-actions.actions';
import {
  getClaimPaymentIntentSecret,
  getIsPollingForPaymentCompletion,
  getOrderCustomerActionsByIds,
} from '../../redux/order-customer-actions/order-customer-actions.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { fetchOrderCustomerRequested, FETCH_ORDER_CUSTOMER_STORE_KEY } from '../../redux/order/order.actions';
import { getCurrentOrderCustomer } from '../../redux/order/order.selectors';
import { PaymentType } from '../../redux/order-customer-actions/order-customer-actions.types';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import { PaymentOptionWrapper, PaymentOptionButton } from './components';
import { Header3 } from '../../components/Typography/index';
import { Container, Row, Col } from 'react-grid-system';

interface ArbitrationOutcomePaymentProps {
  orderId: string;
}

const ArbitrationOutcomePayment: React.FC<ArbitrationOutcomePaymentProps> = ({ orderId }) => {
  const dispatch = useDispatch();
  const [paymentType, setPaymentType] = useState<OrderCustomerActionDTOTypeIdEnum | undefined>();

  const currentCustomerId = useSelector(getCurrentCustomerId);
  const paymentIntentSecret = useSelector(getClaimPaymentIntentSecret);
  const getPayLoadingSelector = createLoadingSelector([PAY_STORE_KEY]);
  const isPayLoading = useSelector(getPayLoadingSelector);
  const currentOrderCustomer = useSelector(getCurrentOrderCustomer);
  const currentOrderCustomerFee = get(currentOrderCustomer, 'fee');
  const nextActionIds = get(currentOrderCustomer, 'nextAction.orderCustomerActionIds', []);

  const nextActions = useSelector(getOrderCustomerActionsByIds(nextActionIds));
  const remainingBalanceAction = nextActions.find(
    (a) => a.typeId === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE
  );

  const amountRemaining = get(remainingBalanceAction, 'details.amountRemaining');
  const amount = paymentType === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY ? currentOrderCustomerFee : amountRemaining;
  const completeNextAction = nextActions.find((a) => a.completedAt);

  const paymentTitle =
    paymentType === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE
      ? 'The remaining balance for this Adjudication is'
      : 'Your share of this Adjudication is';
  const isRemaningBalanceNotEqualToOrderCustomerFee = currentOrderCustomerFee !== amountRemaining;

  useEffect(() => {
    // check everything is loaded
    // AND
    // paymentType has not been set
    if (nextActionIds.length && currentOrderCustomerFee && !paymentType) {
      const initialType = remainingBalanceAction
        ? OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE
        : OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY;
      setPaymentType(initialType);
    }
  }, [
    nextActionIds,
    remainingBalanceAction,
    completeNextAction,
    paymentType,
    currentOrderCustomerFee,
    amountRemaining,
    isRemaningBalanceNotEqualToOrderCustomerFee,
  ]);

  const pageLoadingSelector = createLoadingSelector([FETCH_ORDER_CUSTOMER_STORE_KEY, GET_CLAIM_PAYMENT_INTENT_STORE_KEY]);
  const isPageLoading = useSelector(pageLoadingSelector);
  const isPolling = useSelector(getIsPollingForPaymentCompletion);

  const submitPayment = (stripe: Stripe, cardNumberElement: StripeCardNumberElement) =>
    dispatch(payRequested({ stripe, paymentIntentSecret, type: PaymentType.ARBITRATION, cardNumberElement }));

  useEffect(() => {
    dispatch(fetchOrderCustomerRequested({ customerId: currentCustomerId, orderId }));
  }, [dispatch, orderId, currentCustomerId]);

  useEffect(() => {
    if (paymentType) {
      dispatch(getClaimPaymentIntentRequested(currentCustomerId, orderId, paymentType));
    }
  }, [dispatch, orderId, currentCustomerId, paymentType]);

  const actionsGreaterThanOne = nextActionIds.length > 1;

  // more than one action
  // AND
  // NO complete actions
  // AND
  // remaining balance is not equal to OC fee
  const shouldShowPaymentOptions = actionsGreaterThanOne && !completeNextAction && isRemaningBalanceNotEqualToOrderCustomerFee;

  const isLoading = isPageLoading || isPolling || isPayLoading;

  return (
    <>
      {shouldShowPaymentOptions ? (
        <Container>
          <Row>
            <Col sm={12}>
              <div style={{ paddingTop: '1.75rem' }}>
                <PaymentOptionWrapper>
                  <Header3>How much would you like to pay?</Header3>
                  <div>
                    <PaymentOptionButton
                      paymentType={OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE}
                      selected={paymentType === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE}
                      buttonType="tertiary"
                      onClick={() => setPaymentType(OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE)}
                    >
                      Remaining Balance
                    </PaymentOptionButton>
                    <PaymentOptionButton
                      paymentType={OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY}
                      selected={paymentType === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY}
                      buttonType="tertiary"
                      onClick={() => setPaymentType(OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY)}
                    >
                      Your Share
                    </PaymentOptionButton>
                  </div>
                </PaymentOptionWrapper>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}

      <PaymentContainer
        fullWidth
        isSubmitting={isLoading}
        amount={`${amount}`}
        submitCard={submitPayment}
        type={PaymentType.ARBITRATION}
        title={paymentTitle}
      />
    </>
  );
};

export default ArbitrationOutcomePayment;

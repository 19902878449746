// Create a hook to seperate out logic.
import React from 'react';

export const useHubspot = (portalId: string) => {
  const [hasLoaded, setHasLoaded] = React.useState(false);

  React.useEffect(() => {
    (window as any).hsConversationsSettings = { loadImmediately: false };
    // Add event listener.
    (window as any).hsConversationsOnReady = [
      () => {
        setHasLoaded(true);
      },
    ];

    // Create script component.
    let script = document.createElement('script');
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      (window as any).hsConversationsOnReady = [];
    };
  }, [portalId]);

  return {
    hasLoaded,
  };
};
